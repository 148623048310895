import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import config from "../config";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Carousel from "react-bootstrap/Carousel";
import { useParams } from "react-router-dom";
import { onError } from "../lib/errorLib";
import "./Athlete.css";

export default function Athlete() {
  const { id } = useParams();
  const [athlete, setAthlete] = useState();
  const [highlights, setHighlights] = useState([]);
  const [teams, setTeams] = useState([]);
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    function loadAthlete() {
      return API.get("esp", `/athlete/${id}`);
    }

    function loadHighlights(espId) {
      return API.get("esp", `/athlete/highlights/${espId}`);
    }

    function loadTeams(espId) {
      return API.get("esp", `/athlete/teams/${espId}`);
    }

    function loadSchedule(espId) {
      return API.get("esp", `/athlete/schedule/${espId}`);
    }

    async function onLoad() {
      try {
        const athlete = await loadAthlete();
        setAthlete(athlete);
        if (athlete) {
          document.title =
            "EliteSoccerPlayers.com - " +
            athlete.nameFirst +
            " " +
            athlete.nameLast +
            " " +
            athlete.addrState +
            " " +
            athlete.graduationYear;
        }
        const highlights = await loadHighlights(athlete.espId);
        setHighlights(highlights);

        const teams = await loadTeams(athlete.espId);
        setTeams(teams);

        const schedule = await loadSchedule(athlete.espId);
        setSchedule(schedule);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function renderTeamList(teams) {
    return (
      <>
        {teams.map(
          (
            {
              espId,
              displayName,
              jerseyNumber,
              dateStart,
              dateEnd,
              orgName,
              primaryPosition,
              secondryPosition,
              coachName,
              email,
              phone,
              link,
              scheduleLink,
              achievements,
            },
            i
          ) => (
            <Row className="show-grid" key={espId}>
              {i > 0 && (
                <Col xs={12}>
                  <hr />
                </Col>
              )}
              <Col xs={4} className="card-col">
                <b>Team:</b>
              </Col>
              <Col xs={8} className="card-col">
                {displayName}
              </Col>
              <Col xs={4} className="card-col">
                <b>Club:</b>
              </Col>
              <Col xs={8} className="card-col">
                {orgName}
              </Col>
              <Col xs={4} className="card-col">
                <b>Jersey #:</b>
              </Col>
              <Col xs={8} className="card-col">
                {jerseyNumber}
              </Col>
              <Col xs={4} className="card-col">
                <b>Primary Pos:</b>
              </Col>
              <Col xs={8} className="card-col">
                {primaryPosition}
              </Col>
              <Col xs={4} className="card-col">
                <b>Secondry Pos:</b>
              </Col>
              <Col xs={8} className="card-col">
                {secondryPosition}
              </Col>
              <Col xs={4} className="card-col">
                <b>Dates:</b>
              </Col>
              <Col xs={8} className="card-col">
                {dateStart} - {dateEnd}
              </Col>
              {link && (
                <>
                  <Col xs={4} className="card-col">
                    <b>Link:</b>
                  </Col>
                  <Col xs={8} className="card-col">
                    <a href={link} target="_blank" rel="noreferrer">
                    {link.length > 32 ? link.substring(0,30) + '...' : link}
                    </a>
                  </Col>
                </>
              )}
              {scheduleLink && (
                <>
                  <Col xs={4} className="card-col">
                    <b>Schedule:</b>
                  </Col>
                  <Col xs={8} className="card-col">
                    <a href={scheduleLink} target="_blank" rel="noreferrer">
                    {scheduleLink.length > 32 ? scheduleLink.substring(0,30) + '...' : scheduleLink}
                    </a>
                  </Col>
                </>
              )}
              <Col xs={4} className="card-col">
                <b>Coach:</b>
              </Col>
              <Col xs={8} className="card-col">
                {coachName}
              </Col>
              {email && (
                <>
                  <Col xs={4} className="card-col">
                    &nbsp;
                  </Col>
                  <Col xs={8} className="card-col">
                    <a href={"mailto:" + email}>{email}</a>
                  </Col>
                </>
              )}
              {phone && (
                <>
                  <Col xs={4} className="card-col">
                    &nbsp;
                  </Col>
                  <Col xs={8} className="card-col">
                    <a href={"tel:" + phone.replace(/[^0-9]/g, "")}>{phone}</a>
                  </Col>
                </>
              )}
              {achievements && (
                <>
                  <Col xs={4} className="card-col">
                    <b>Achievements:</b>
                  </Col>
                  <Col xs={8} className="card-col">
                    {achievements}
                  </Col>
                </>
              )}
            </Row>
          )
        )}
      </>
    );
  }

  function renderScheduleList(schedule) {
    return (
      <>
        {schedule.map(
          (
            { espId, displayName, dateStart, dateEnd, city, addrState, link },
            i
          ) => (
            <Row className="show-grid" key={espId}>
              {i > 0 && (
                <Col xs={12}>
                  <hr />
                </Col>
              )}
              <Col xs={4} className="card-col">
                <b>Name:</b>
              </Col>
              <Col xs={8} className="card-col">
                <a href={link} target="_blank" rel="noreferrer">
                  {displayName}
                </a>
              </Col>
              <Col xs={4} className="card-col">
                <b>Date(s):</b>
              </Col>
              <Col xs={8} className="card-col">
                {dateStart}
                {" - "}
                {dateEnd}
              </Col>
              <Col xs={4} className="card-col">
                <b>City, State:</b>
              </Col>
              <Col xs={8} className="card-col">
                {city}
                {", "}
                {addrState}
              </Col>
            </Row>
          )
        )}
      </>
    );
  }

  function renderHighlightList(highlights) {
    return (
      <>
        {highlights.map(({ espId, displayName, link }) => (
          <Row className="show-grid" key={espId}>
            <Col xs={12} className="card-col pad-bottom-5px">
              {link.endsWith(".mp4") ? (
                <video controls autoplay muted className="width-inherit">
                  <source src={link} type="video/mp4" />
                </video>
              ) : (
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                >
                  {displayName}
                </a>
              )}
            </Col>
          </Row>
        ))}
      </>
    );
  }

  function renderCarouselItems(athName, athPhotos) {
    return [{}].concat(athPhotos).map((photo, i) =>
      i !== 0 ? (
        <Carousel.Item key={i} interval={6000}>
          <Image
            variant="top"
            className="img-fluid"
            alt={athName}
            src={config.UPLOAD_URL + photo}
          />
        </Carousel.Item>
      ) : null
    );
  }

  function renderCarousel(attchmnt, athName, athPhotos, gender) {
    return (
      <Carousel data-interval="false" responsive="true">
        {attchmnt ? (
          <Carousel.Item key="a" interval={8000}>
            <Image
              variant="top"
              className="img-fluid"
              alt={athName}
              src={config.UPLOAD_URL + attchmnt}
            />
          </Carousel.Item>
        ) : (
          ""
        )}
        {athPhotos ? (
          renderCarouselItems(athName, athPhotos)
        ) : gender && gender === "Female" ? (
          <Image
            variant="top"
            className="img-fluid"
            alt={athName}
            src="/images/soccer-player-female-silhouette.png"
          />
        ) : gender && gender === "Male" ? (
          <Image
            variant="top"
            className="img-fluid"
            alt={athName}
            src="/images/soccer-player-male-silhouette.png"
          />
        ) : (
          <Image
            variant="top"
            className="img-fluid"
            alt={athName}
            src="/images/soccer-player-silhouette.png"
          />
        )}
      </Carousel>
    );
  }

  return (
    <div className="Athlete">
      {athlete && (
        <div className="container">
          <Row className="show-grid section">
            <Col xs={12} xl={5}>
              <span className="img-profile">
              {athlete.pictureFiles && athlete.pictureFiles.length > 0 ? (
                renderCarousel(
                  athlete.picture,
                  athlete.seoName,
                  athlete.pictureFiles,
                  athlete.gender
                )
              ) : athlete.picture ? (
                <Image
                  className="profile-img"
                  src={config.UPLOAD_URL + athlete.picture}
                  alt={athlete.seoName}
                />
              ) : athlete.gender && athlete.gender === "Female" ? (
                <Image
                  className="profile-img"
                  src="/images/soccer-player-female-silhouette.png"
                  alt={athlete.seoName}
                />
              ) : athlete.gender && athlete.gender === "Male" ? (
                <Image
                  className="profile-img"
                  src="/images/soccer-player-male-silhouette.png"
                  alt={athlete.seoName}
                />
              ) : (
                <Image
                  className="profile-img"
                  src="/images/soccer-player-silhouette.png"
                  alt={athlete.seoName}
                />
              )}
              </span>
            </Col>
            <Col xs={12} xl={7}>
              <Row className="show-grid ">
                <Col xs={12}>&nbsp;</Col>
                <Col xs={12}>
                  <h1>
                    {athlete.nameFirst}&nbsp;{athlete.nameLast}
                  </h1>
                </Col>
                <Col xs={12}>&nbsp;</Col>
                <Col xs={4}>
                  <h5>Graduation Year:</h5>
                </Col>
                <Col xs={8}>
                  <span className="large-txt">{athlete.graduationYear}</span>
                </Col>
                <Col xs={12}>&nbsp;</Col>
                <Col xs={4}>
                  <h5>Primary Position:</h5>
                </Col>
                <Col xs={8}>
                  <span className="large-txt">{athlete.primaryPosition}</span>
                </Col>
                <Col xs={12}>&nbsp;</Col>
                <Col xs={4}>
                  <h5>Secondry Position:</h5>
                </Col>
                <Col xs={8}>
                  <span className="large-txt">{athlete.secondryPosition}</span>
                </Col>
                <Col xs={12}>&nbsp;</Col>
                {athlete.city ? (
                  <>
                    <Col xs={4}>
                      <h5>City, State:</h5>
                    </Col>

                    <Col xs={8}>
                      <span className="large-txt">
                        {athlete.city},&nbsp;{athlete.addrState}
                      </span>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={4}>
                      <h5>State:</h5>
                    </Col>
                    <Col xs={8}>
                      <span className="large-txt">{athlete.addrState}</span>
                    </Col>
                  </>
                )}
                <Col xs={12}>&nbsp;</Col>
                <Col xs={4}>
                  <h5>Height:</h5>
                </Col>
                <Col xs={8}>
                  <span className="large-txt">{athlete.height}</span>
                </Col>
                {athlete.weight && (
                  <>
                    <Col xs={12}>&nbsp;</Col>
                    <Col xs={4}>
                      <h5>Weight:</h5>
                    </Col>
                    <Col xs={8}>
                      <span className="large-txt">{athlete.weight}</span>
                    </Col>
                  </>
                )}
                <Col xs={12}>&nbsp;</Col>
                <Col xs={4}>
                  <h5>Status:</h5>
                </Col>
                <Col xs={8}>
                  <span className="large-txt">{athlete.recruitmentStatus}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          {highlights && (
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>Highlights &amp; Other Profiles</h4>
              </Col>
              <Col xs={12}>
                {renderHighlightList(highlights)}
              </Col>
            </Row>
          )}
          {athlete.bio && (
            <>
              <Row className="show-grid section">
                <Col xs={12}>
                  <h4>Bio</h4>
                </Col>
                <Col xs={12}>
                  {athlete.bio}
                </Col>
              </Row>
            </>
          )}
          {teams && (
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>Teams</h4>
              </Col>
              <Col xs={12}>
                {renderTeamList(teams)}
              </Col>
            </Row>
          )}
          {schedule && (
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>Schedule</h4>
              </Col>
              <Col xs={12}>
                <>{renderScheduleList(schedule)}</>
              </Col>
            </Row>
          )}
          <Row className="show-grid section">
            <Col xs={12}>
              <h4>Academic</h4>
            </Col>
            <Col xs={12}>
              <Row className="show-grid">
                <Col xs={4}>
                  <b>High School:</b>
                </Col>
                <Col xs={8}>{athlete.highSchoolName}</Col>
                <Col xs={4}>
                  <b>HS Grad Year:</b>
                </Col>
                <Col xs={8}>{athlete.highSchooGradYear}</Col>
                {athlete.collegeName && (
                  <>
                    <Col xs={4}>
                      <b>College Name:</b>
                    </Col>
                    <Col xs={8}>{athlete.collegeName}</Col>
                  </>
                )}
                {athlete.collegeGradYear && (
                  <>
                    <Col xs={4}>
                      <b>College Grad Year:</b>
                    </Col>
                    <Col xs={8}>{athlete.collegeGradYear}</Col>
                  </>
                )}
                {athlete.gpa && (
                  <>
                    <Col xs={4}>
                      <b>GPA:</b>
                    </Col>
                    <Col xs={8}>{athlete.gpa}</Col>
                  </>
                )}
                {athlete.sat && (
                  <>
                    <Col xs={4}>
                      <b>SAT:</b>
                    </Col>
                    <Col xs={8}>{athlete.sat}</Col>
                  </>
                )}
                {athlete.act && (
                  <>
                    <Col xs={4}>
                      <b>ACT:</b>
                    </Col>
                    <Col xs={8}>{athlete.act}</Col>
                  </>
                )}
                {athlete.classRank && (
                  <>
                    <Col xs={4}>
                      <b>Class Rank:</b>
                    </Col>
                    <Col xs={8}>{athlete.classRank}</Col>
                  </>
                )}
                {athlete.academicAchievements && (
                  <>
                    <Col xs={4}>
                      <b>Achievements:</b>
                    </Col>
                    <Col xs={8}>{athlete.academicAchievements}</Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
          {athlete.otherActivities && (
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>Other Activities</h4>
              </Col>
              <Col xs={12}>
                <Row className="show-grid">
                  <Col xs={12}>{athlete.otherActivities}</Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row className="show-grid section">
            <Col xs={12}>
              <h4>Contact Information</h4>
            </Col>
            <Col xs={12}>
              <Row className="show-grid">
                <Col xs={4}>
                  <b>Name:</b>
                </Col>
                <Col xs={8}>
                  {athlete.nameFirst}&nbsp;{athlete.nameLast}
                </Col>
                {athlete.city ? (
                  <>
                    <Col xs={4}>
                      <b>City, State:</b>
                    </Col>
                    <Col xs={8}>
                      {athlete.city},&nbsp;{athlete.addrState}
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={4}>
                      <b>State:</b>
                    </Col>
                    <Col xs={8}>{athlete.addrState}</Col>
                  </>
                )}
                {athlete.email && (
                  <>
                    <Col xs={4}>
                      <b>Email:</b>
                    </Col>
                    <Col xs={8}>
                      <a href={"mailto:" + athlete.email}>{athlete.email}</a>
                    </Col>
                  </>
                )}
                {athlete.phone && (
                  <>
                    <Col xs={4}>
                      <b>Phone:</b>
                    </Col>
                    <Col xs={8}>
                      <a href={"tel:" + athlete.phone.replace(/[^0-9]/g, "")}>
                        {athlete.phone}
                      </a>
                    </Col>
                  </>
                )}
                {athlete.primaryGuardianName && (
                  <>
                    <Col xs={12}>&nbsp;</Col>
                    <Col xs={4}>
                      <b>Name:</b>
                    </Col>
                    <Col xs={8}>
                      {athlete.primaryGuardianName} (
                      {athlete.primaryGuardianRelationship})
                    </Col>
                    {athlete.primaryGuardianEmail && (
                      <>
                        <Col xs={4}>
                          <b>Email:</b>
                        </Col>
                        <Col xs={8}>
                          <a href={"mailto:" + athlete.primaryGuardianEmail}>
                            {athlete.primaryGuardianEmail}
                          </a>
                        </Col>
                      </>
                    )}
                    {athlete.primaryGuardianPhone && (
                      <>
                        <Col xs={4}>
                          <b>Phone:</b>
                        </Col>
                        <Col xs={8}>
                          <a
                            href={
                              "tel:" +
                              athlete.primaryGuardianPhone.replace(
                                /[^0-9]/g,
                                ""
                              )
                            }
                          >
                            {athlete.primaryGuardianPhone}
                          </a>
                        </Col>
                      </>
                    )}
                  </>
                )}
                {athlete.secondaryGuardianName && (
                  <>
                    <Col xs={12}>&nbsp;</Col>
                    <Col xs={4}>
                      <b>Name:</b>
                    </Col>
                    <Col xs={8}>
                      {athlete.secondaryGuardianName} (
                      {athlete.secondaryGuardianRelationship})
                    </Col>
                    {athlete.secondaryGuardianEmail && (
                      <>
                        <Col xs={4}>
                          <b>Email:</b>
                        </Col>
                        <Col xs={8}>
                          <a href={"mailto:" + athlete.secondaryGuardianEmail}>
                            {athlete.secondaryGuardianEmail}
                          </a>
                        </Col>
                      </>
                    )}
                    {athlete.secondaryGuardianPhone && (
                      <>
                        <Col xs={4}>
                          <b>Phone:</b>
                        </Col>
                        <Col xs={8}>
                          <a
                            href={
                              "tel:" +
                              athlete.secondaryGuardianPhone.replace(
                                /[^0-9]/g,
                                ""
                              )
                            }
                          >
                            {athlete.secondaryGuardianPhone}
                          </a>
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Row>
            </Col>
          </Row>
          <Row className="show-grid section">
            <Col xs={12}>
              <h4>Social Media Account</h4>
            </Col>
            <Col xs={12}>
              {athlete.twitter && (
                <Row className="show-grid">
                  <Col xs={4}>
                    <b>Twiter:</b>
                  </Col>
                  <Col xs={8}>
                    <a
                      href={"https://twitter.com/" + athlete.twitter}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {athlete.twitter}
                    </a>
                  </Col>
                </Row>
              )}
              {athlete.instagram && (
                <Row className="show-grid">
                  <Col xs={4}>
                    <b>Instagram:</b>
                  </Col>
                  <Col xs={8}>
                    <a
                      href={"https://instagram.com/" + athlete.instagram}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {athlete.instagram}
                    </a>
                  </Col>
                </Row>
              )}
              {athlete.facebook && (
                <Row className="show-grid">
                  <Col xs={4}>
                    <b>Facebook:</b>
                  </Col>
                  <Col xs={8}>
                    <a
                      href={"https://facebook.com/public/" + athlete.facebook}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {athlete.facebook}
                    </a>
                  </Col>
                </Row>
              )}
              {athlete.snapchat && (
                <Row className="show-grid">
                  <Col xs={4}>
                    <b>Snapchat:</b>
                  </Col>
                  <Col xs={8}>{athlete.snapchat}</Col>
                </Row>
              )}
              {athlete.tiktok && (
                <Row className="show-grid">
                  <Col xs={4}>
                    <b>Tiktok:</b>
                  </Col>
                  <Col xs={8}>{athlete.tiktok}</Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row className="show-grid section">
            <Col xs={12}>
              <h4>Other</h4>
            </Col>
            <Col xs={12}>
            {athlete.guestPlayer && (
              <Row className="show-grid">
                <Col xs={4}>
                  <b>Open to Guest Playing Opportunities:</b>
                </Col>
                <Col xs={8}>{athlete.guestPlayer}</Col>
                </Row>
            )}
              <Row className="show-grid">
                <Col xs={4}>
                  <b>Profile Views:</b>
                </Col>
                <Col xs={8}>{athlete.viewed}</Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
