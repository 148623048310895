import React, { useState, useEffect } from "react";
import config from "../config";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../lib/contextLib";
import { useParams } from "react-router-dom";
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";
import "./Team.css";

export default function Team() {
  const [athletes, setAthletes] = useState();
  const { clubId } = useParams();
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function loadAthletes() {
      return API.get("esp", `/team/athletes/${clubId}`);
    }

    document.title = clubId + " - EliteSoccerPlayers.com!";
    async function onLoad() {
      try {
        const athletes = await loadAthletes();
        setAthletes(athletes);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated, clubId]);

  function renderAthleteList(athletes) {
    return (
      <>
        {athletes.map(
          ({
            espKey,
            nameFirst,
            nameLast,
            seoName,
            primaryPosition,
            graduationYear,
            city,
            addrState,
            picture,
            jerseyNumber,
            teamId,
            teamId2,
            displayOrder,
          }) => (
            <Col xs={12} xl={4} className="card-col" key={espKey}>
              <Card>
                <span className="card-img-top">
                  {picture ? (
                    <Card.Img
                      variant="top"
                      className="img-fluid"
                      src={config.UPLOAD_URL + picture}
                    />
                  ) : (
                    <Card.Img
                      variant="top"
                      className="img-fluid"
                      src="https://static.wixstatic.com/media/437a91_1cf77244552f4b9da1a3fa3ff0f8a03b~mv2.png/v1/fill/w_112,h_64,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/TNT%20Solo%20Transparent%20BG.png"
                    />
                  )}
                </span>
                <Card.Body>
                  <Card.Title>
                    {nameFirst}&nbsp;{nameLast}&nbsp;#{jerseyNumber}
                  </Card.Title>
                  {displayOrder !== 1 ? (
                    <>
                      <Card.Text>
                        {primaryPosition}
                        <br />
                        Team:&nbsp;{teamId}
                        {teamId2 ? <>,&nbsp;{teamId2}</> : ""}
                        <br />
                        Grad&nbsp;Year:&nbsp;{graduationYear}
                        <br />
                        {city},&nbsp;{addrState}
                      </Card.Text>
                      <Button
                        variant="primary"
                        className="card-footer-btn"
                        href={"/athlete/" + seoName}
                      >
                        View Profile
                      </Button>
                    </>
                  ) : (
                    <Card.Text>
                      Team:&nbsp;{teamId}
                      {teamId2 ? <>,&nbsp;{teamId2}</> : ""}
                      <br />
                      <br />
                      <br />
                      <br />
                    </Card.Text>
                  )}
                </Card.Body>
              </Card>
            </Col>
          )
        )}
      </>
    );
  }

  function renderLander(athletes) {
    return (
      <div className="lander">
        <Container>
          <Row>
            <Col xs={2}>
              <img
                alt="TNT Soccer"
                src="https://static.wixstatic.com/media/437a91_1cf77244552f4b9da1a3fa3ff0f8a03b~mv2.png/v1/fill/w_112,h_64,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/TNT%20Solo%20Transparent%20BG.png"
              />
            </Col>
            <Col xs={4}>
              <h1>{clubId} Soccer Players</h1>
              <a href="https://www.tnt-soccer.com/">
                https://www.tnt-soccer.com/
              </a>
            </Col>
            <Col xs={6}>
              Coach: Micah McMorris
              <br />
              Phone: 319-231-7024
              <br />
              Email: micahmcmorris@icloud.com
            </Col>
          </Row>
          <Row className="card-row">
            {(isLoading || !athletes) && (
              <>
                <br />
                <h3>&nbsp;&nbsp;Loading...</h3>
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            {!isLoading && renderAthleteList(athletes)}
          </Row>
        </Container>
      </div>
    );
  }

  return <div className="Team">{renderLander(athletes)}</div>;
}
