import React, { useState, useEffect, useRef } from "react";
import config from "../config";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import AWS from "aws-sdk";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import "./ContactUs.css";

export default function ContactUs() {
  const history = useHistory();
  const [contactUs, setContactUs] = useState();
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const onhCaptchaLoad = () => {
    captchaRef.current.execute();
  };

  useEffect(() => {
    async function onLoad() {
      const fields = ["name", "phone", "email", "subject", "message"];
      fields.forEach((field) => setValue(field, ""));
      setContactUs(contactUs);
    }

    onLoad();
  }, [contactUs, setValue, token]);

  async function onSubmit(data) {
    console.log("Send:", data);

    // Initialize the Amazon Cognito credentials provider
    AWS.config.region = "us-east-1"; // Region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
    });

    if (!token || token == null) {
      alert("Please complete the hCaptcha dialog! - " + token);
      return;
    }

    var sns = new AWS.SNS();

    var params = {
      //Message: 'Hello topic', /* required */
      Message:
        "Name: " +
        data.name +
        "\n" +
        " Phone: " +
        data.phone +
        "\n" +
        " Email: " +
        data.email +
        "\n" +
        " Subject: " +
        data.subject +
        "\n" +
        " Message: " +
        data.message +
        "\n\n",
      Subject: config.CONTACT_US_SUBJECT,
      TopicArn: config.CONTACT_US_TOPIC_ARN,
    };

    sns.publish(params, function (err, data) {
      if (err) {
        console.log(err, err.stack); // an error occurred
      } else {
        //console.log(data); // successful response
      }
    });

    alert("Your message has been sent!");

    history.push("/");
  }

  return (
    <div className="ContactUs container">
      <h1>Contact Us</h1>
      <p>
        Please complete the form below to send our staff any questions you have
        about EliteSoccerPlayers.com. Please contact the player, coach or
        guardian directly for questions about an athlete.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Col xs={12} md={4}>
          <label htmlFor="name">Your Name:</label>
        </Col>
        <Col xs={12} md={8}>
          <input
            {...register("name", {
              required: true,
              minLength: 5,
              maxLength: 35,
            })}
          />
          <p className="validation">
            {errors.name?.type === "required" && "Field is required"}
          </p>
        </Col>
        <Col xs={12} md={4}>
          <label htmlFor="phone">Phone:</label>
        </Col>
        <Col xs={12} md={8}>
          <input
            {...register("phone", {
              required: true,
              minLength: 10,
              maxLength: 17,
            })}
          />
          <p className="validation">
            {errors.phone?.type === "required" && "Field is required"}
            {errors.phone?.type === "pattern" && "Please correct format"}
          </p>
        </Col>
        <Col xs={12} md={4}>
          <label htmlFor="email">Email Address:</label>
        </Col>
        <Col xs={12} md={8}>
          <input
            {...register("email", {
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email",
              },
            })}
          />
          <p className="validation">
            {errors.email?.type === "required" && "Field is required"}
            {errors.email?.type === "pattern" && "Please correct format"}
          </p>
        </Col>
        <Col xs={12} md={4}>
          <label htmlFor="subject">Subject:</label>
        </Col>
        <Col xs={12} md={8}>
          <input
            {...register("subject", {
              required: true,
              maxLength: 35,
            })}
          />
          <p className="validation">
            {errors.subject?.type === "required" && "Field is required"}
            {errors.subject?.type === "pattern" && "Please correct format"}
          </p>
        </Col>
        <Col xs={12} md={4}>
          <label htmlFor="message">Message:</label>
        </Col>
        <Col xs={12} md={8}>
          <textarea
            rows="2"
            cols="52"
            className="textarea"
            {...register("message", {
              maxLength: 500,
            })}
          />
          <p className="validation">
            {errors.message?.type === "required" && "Field is required"}
          </p>
        </Col>
        <Col xs={12}>
          <HCaptcha
            sitekey="bd0b4e41-8d27-445c-8709-6b0c75bcc8dc"
            onLoad={onhCaptchaLoad}
            onVerify={setToken}
            ref={captchaRef}
          />
        </Col>
        <Col xs={12}>&nbsp;</Col>
        <Col xs={12}>
          <Button type="submit" size="lg">
            Send Message
          </Button>
        </Col>
        <Col xs={12}>&nbsp;</Col>
      </form>
    </div>
  );
}
