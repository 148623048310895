import React, { useRef, useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import { s3Upload, s3Delete } from "../lib/awsLib";
import config from "../config";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { onError } from "../lib/errorLib";
import "./TeamEdit.css";

export default function TeamEdit() {
  const { isAuthenticated, userId } = useAppContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const isAddMode = false;
  const file = useRef(null);
  const [team, setTeam] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    function loadTeam() {
      return API.get("esp", `/team/edit/${userId}`);
    }

    async function onLoad() {
      try {
        setIsLoading(true);
        if (!isAddMode) {
          await loadTeam().then((team) => {
            if (!team) {
              team = {};
            }
            const fields = [
              "espId",
              "espKey",
              "bio",
              "city",
              "nameFirst",
              "email",
              "facebook",
              "instagram",
              "link",
              "orgName",
              "phone",
              "picture",
              "players",
              "seoName",
              "snapchat",
              "addrState",
              "tiktok",
              "twitter",
              "scheduleLink",
              "achievements",
            ];
            fields.forEach((field) => setValue(field, team[field]));
            setTeam(team);

            if (team.players) {
              setValue("player01", team.players[0]);
              setValue("player02", team.players[1]);
              setValue("player03", team.players[2]);
              setValue("player04", team.players[3]);
              setValue("player05", team.players[4]);
              setValue("player06", team.players[5]);
              setValue("player07", team.players[6]);
              setValue("player08", team.players[7]);
              setValue("player09", team.players[8]);
              setValue("player10", team.players[9]);
              setValue("player11", team.players[10]);
              setValue("player12", team.players[11]);
              setValue("player13", team.players[12]);
              setValue("player14", team.players[13]);
              setValue("player15", team.players[14]);
              setValue("player16", team.players[15]);
              setValue("player17", team.players[16]);
              setValue("player18", team.players[17]);
              setValue("player19", team.players[18]);
              setValue("player20", team.players[19]);
              setValue("player21", team.players[20]);
              setValue("player22", team.players[21]);
              setValue("player23", team.players[22]);
              setValue("player24", team.players[23]);
            }

            if (team) {
              document.title =
                "Edit " + team.displayName + " - EliteSoccerPlayers.com";
            }
          });
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);

        onError(e);
      }
    }

    onLoad();
  }, [isAuthenticated, isAddMode, setValue, userId]);

  function formatFilename(str) {
    return str.substring(str.lastIndexOf("--") + 2);
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  function saveTeam(team) {
    return API.put("esp", `/team/update/${userId}`, {
      body: team,
    });
  }

  async function onSubmit(data) {
    setIsLoading(true);

    console.log("Save:", data);

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    if (file.current) {
      let prevFileName = data.picture;
      data.picture = await s3Upload(data.espId, file.current);
      await s3Delete(prevFileName);
    }

    data.displayOrder = 200;

    saveTeam(data).then((res) => {
      history.push("/dashboard");
    });
  }

  return (
    <div className="TeamEdit">
      {(isLoading || !team) && (
        <>
          <br />
          <h3>&nbsp;&nbsp;Loading...</h3>
          <br />
          <br />
          <br />
          <br />
        </>
      )}
      {!isLoading && team && (
        <>
          <div className="lander">
            <h1>Edit My Team Profile</h1>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" {...register("espKeyOrig")} />
            <Row className="section">
              <Col xs={12} xl={4}>
                {team.picture ? (
                  <Image
                    src={config.UPLOAD_URL + team.picture}
                    width="480px"
                    alt="ESP"
                  />
                ) : (
                  <Image
                    src="/images/esp_logo_green.jpeg"
                    width="480px"
                    alt="ESP"
                  />
                )}
                {team.picture && <p>{formatFilename(team.picture)}</p>}
                <input onChange={handleFileChange} type="file" />
              </Col>
              <Col xs={12} xl={8}>
                <Row className="show-grid">
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label>Team Name:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("nameFirst", {
                        required: true,
                        maxLength: 100,
                      })}
                    />
                    <p className="validation">
                      {errors.nameFirst?.type === "required" &&
                        "Field is required"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12}>&nbsp;</Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Bio</b>
                </h4>
              </Col>
              <Col xs={12}>
                <textarea
                  rows="2"
                  cols="52"
                  className="textarea"
                  {...register("bio", {
                    maxLength: 500,
                  })}
                />
                <p className="validation">
                  {errors.bio?.type === "required" && "Field is required"}
                </p>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Contact Information</b>
                </h4>
              </Col>
              <Col xs={12}>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Email:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Please enter a valid email",
                        },
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.email?.type === "required" && "Field is required"}
                      {errors.email?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Phone:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("phone", {
                        minLength: 10,
                        maxLength: 17,
                        required: true,
                      })}
                    />
                    <p className="validation">
                      {errors.phone?.type === "required" && "Field is required"}
                      {errors.phone?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>City:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("city", {
                        pattern: /^[A-Za-z]+$/i,
                        required: true,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.city?.type === "required" && "Field is required"}
                      {errors.city?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>State:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select {...register("addrState", { required: true })}>
                      <option value="---">---</option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District of Columbia">
                        District of Columbia
                      </option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Guam">Guam</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Northern Marianas Islands">
                        Northern Marianas Islands
                      </option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Virgin Islands">Virgin Islands</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                    </select>
                    <p className="validation">
                      {errors.addrState?.type === "required" &&
                        "Field is required"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Social Media Account</b>
                </h4>
              </Col>
              <Col xs={12}>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Twiter:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("twitter", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.twitter?.type === "required" &&
                        "Field is required"}
                      {errors.twitter?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Instagram:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("instagram", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.instagram?.type === "required" &&
                        "Field is required"}
                      {errors.instagram?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Facebook:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("facebook", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.facebook?.type === "required" &&
                        "Field is required"}
                      {errors.facebook?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Snapchat:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("snapchat", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.snapchat?.type === "required" &&
                        "Field is required"}
                      {errors.snapchat?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Tiktok:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("tiktok", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.tiktok?.type === "required" &&
                        "Field is required"}
                      {errors.tiktok?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={12}>&nbsp;</Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Players SEO ID</b>
                </h4>
              </Col>
              <Col xs={12}>
                <Row className="show-grid">
                  <Col xs={12} xl={4}></Col>
                  <Col xs={12} xl={8}>
                    Please enter the players SEO Id for each player. (I.E.
                    Kinsie-Shay-2026-URBANDALE-Iowa)
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 01:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player01", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 02:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player02", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 03:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player03", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 04:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player04", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 05:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player05", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 06:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player06", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 07:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player07", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 08:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player08", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 09:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player09", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 10:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player10", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 11:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player11", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 12:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player12", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 13:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player13", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 14:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player14", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 15:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player15", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 16:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player16", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 17:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player17", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 18:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player18", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 19:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player19", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 20:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player20", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 21:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player21", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 22:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player22", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 23:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player23", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Player 24:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("player24", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={12}>&nbsp;</Col>
            </Row>
            <Row className="show-grid center">
              <Col xs={12}>
                <Button type="submit" size="lg">
                  Save Changes
                </Button>
              </Col>
            </Row>
          </form>
        </>
      )}
    </div>
  );
}
