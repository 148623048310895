import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import ErrorBoundary from "./components/ErrorBoundary";
import { AppContext } from "./lib/contextLib";
import { onError } from "./lib/errorLib";
import Routes from "./Routes";
import "./App.css";

function App() {
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userId, setUserId] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      if (await Auth.currentSession()) {
        // Set isAdmin if they are in Admin group
        await Auth.currentAuthenticatedUser()
          .then((res) => {
            var usrAdm = false;
            var usrId =
              res.signInUserSession.idToken.payload["cognito:username"];
            var adminGroups =
              res.signInUserSession.idToken.payload["cognito:groups"];
            if (
              adminGroups &&
              (adminGroups.includes("ESP-ADMIN") ||
                adminGroups.includes("Admin"))
            ) {
              usrAdm = true;
            }

            setUserId(usrId);
            setIsAdmin(usrAdm);
          })
          .catch((err) => {
            console.error(err);
          });
        userHasAuthenticated(true);
      }
    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    // userHasAuthenticated(false);
    // setIsAdmin(false);
    // setUserId("");

    history.push("/login");
  }

  return (
    !isAuthenticating && (
      <div className="App">
        <Navbar
          fixed="top"
          collapseOnSelect
          bg="dark"
          expand="lg"
          variant="dark"
          className="mb-3 noprint"
        >
          <div className="container">
            <LinkContainer to="/">
              <Navbar.Brand className="font-weight-bold text-muted">
                <Link to="/">
                  <Image
                    src="/images/esp_logo_header_blue.jpeg"
                    height="36px"
                    alt="ESP"
                  />
                </Link>
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Nav activeKey={window.location.pathname}>
                <LinkContainer to="/athletes">
                  <Nav.Link>Athletes</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/college-guide">
                  <Nav.Link>College Guide</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/highlight-video-guide">
                  <Nav.Link>Highlight Video Guide</Nav.Link>
                </LinkContainer>
                {isAuthenticated ? (
                  <>
                    <LinkContainer to="/dashboard">
                      <Nav.Link>My Dashboard</Nav.Link>
                    </LinkContainer>
                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                  </>
                ) : (
                  <>
                    <LinkContainer to="/signup">
                      <Nav.Link>Sign Up</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <Nav.Link>Login</Nav.Link>
                    </LinkContainer>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
        <ErrorBoundary>
          {!isAuthenticating && (
            <AppContext.Provider
              value={{
                isAuthenticated,
                userHasAuthenticated,
                userId,
                isAdmin,
              }}
            >
              <div className="esp-main">
                <Routes />
              </div>
            </AppContext.Provider>
          )}
        </ErrorBoundary>
        <footer className="footerbar bg-dark noprint">
          <div className="container">
            <Row className="padding-left">
              <Col xs={12} xl={12}>
                <p></p>
                <span className="navbar-brand footer-nav">
                  <a href="/">EliteSoccerPlayers.com</a>
                </span>
                <div className="ml-auto nav footer-nav">
                  <a href="/" className="nav-link">
                    Home
                  </a>
                  <a href="/athletes" className="nav-link">
                    Athletes
                  </a>
                  <a href="/college-guide" className="nav-link">
                    College Guide
                  </a>
                  <a href="/highlight-video-guide" className="nav-link">
                    Highlight Video Guide
                  </a>
                  <a href="/contactus" className="nav-link">
                    Contact Us
                  </a>
                  <a href="/privacy" className="nav-link">
                    Privacy Policy
                  </a>
                  {isAuthenticated ? (
                    <>
                      <a href="/edit/athlete" className="nav-link">
                        My Profile
                      </a>
                      <a href="#" onClick={handleLogout} className="nav-link">
                        Logout
                      </a>
                    </>
                  ) : (
                    <>
                      <a href="/signup" className="nav-link">
                        Sign Up
                      </a>
                      <a href="/login" className="nav-link">
                        Login
                      </a>
                    </>
                  )}
                </div>
              </Col>
              <Col xs={12} xl={12}>
                <p></p>
                <p className="padding-right">
                  &copy; 2022-2023 EliteSoccerPlayers.com, All rights reserved.
                </p>
                <p className="padding-right">
                  The information contained on this page is believed to be
                  reliable by it's submitter. However, this information is not
                  guarantee and we urge you to do further investigation on your
                  own.
                </p>
              </Col>
            </Row>
          </div>
        </footer>
      </div>
    )
  );
}

export default App;
