import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../lib/contextLib";

import "./GuideCollege.css";

export default function GuideCollege() {
  const { isAuthenticated } = useAppContext();

  useEffect(() => {
    document.title = "EliteSoccerPlayers.com - Highlight Video Guide";
    async function onLoad() {
      // if (!isAuthenticated) {
      //   return;
      // }
    }

    onLoad();
  }, [isAuthenticated]);

  return (
    <div className="GuideCollege container">
      <center>
        <h1>College Guide</h1>
      </center>
      <br />
      <Row className="show-grid section">
        <Col xs={12}>
          <p>Athletic Scholorships per team if fully funded:</p>
        </Col>
        <Col xs={12} xl={6}>
          <p>Mens:</p>
          <ul>
            <li>D1: 9.9</li>
            <li>D2: 9</li>
            <li>D3: 0 (Academic Scholarships Only)</li>
            <li>NAIA: 12</li>
            <li>Juco: 24</li>
          </ul>
        </Col>
        <Col xs={12} xl={6}>
          <p>Womens:</p>
          <ul>
            <li>D1: 14</li>
            <li>D2: 9.9</li>
            <li>D3: 0 (Academic Scholarships Only)</li>
            <li>NAIA: 12</li>
            <li>Juco: 24</li>
          </ul>
        </Col>
      </Row>
      <Row className="show-grid section">
        <Col xs={12}>
          <p>Questions to ask college coaches:</p>
        </Col>
        <Col xs={12} xl={6}>
          <ul>
            <li>How would you describe your team culture?</li>
            <li>How would you describe your coaching style?</li>
            <li>
              What resources are available on campus for academic support to
              athletes?
            </li>
            <li>What are some of your goals for the program?</li>
            <li>How do you see me contributing to your program?</li>
            <li>
              What are the key attributes that make your best player successful?
            </li>
            <li>
              What does a typical day look like for your student-athletes in
              season?
            </li>
            <li>
              What does a typical day look like for your student-athletes out of
              season?
            </li>
            <li>What are the most popular majors on the team?</li>
            <li>
              Do professors suport athletics and help when there is a missed
              class due to a game/travel?
            </li>
            <li>
              What is campus life like? What do your atheletes do for fun?
            </li>
            <li>What are living arrangements like for your players?</li>
          </ul>
        </Col>
      </Row>
      <Row className="show-grid section">
        <Col xs={12}>
          <p>Things to research about a college:</p>
        </Col>
        <Col xs={12} xl={6}>
          <ul>
            <li>Does it have the major you are interested in?</li>
            <li>How many seniors will the soccer team be losing?</li>
            <li>What has their record been over the last 2-3 years?</li>
            <li>
              What is their player turnover rate? How many new players are they
              bringing in each year?
            </li>
            <li>What are their facilities like?</li>
            <li>How tough is their conference?</li>
            <li>Do current players seem happy?</li>
            <li>Does their playing style suit you?</li>
            <li>
              What does their housing, meal, weight and training facilites look
              like?
            </li>
            <li>
              Is it a college you would go to even if you did not play soccer
              there?
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}
