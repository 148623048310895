import React, { useState, useEffect } from "react";
import config from "../config";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";
import "./Admin.css";

export default function Admin() {
  const [athletes, setAthletes] = useState();
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title =
      "EliteSoccerPlayers.com - Connecting socer players and college coaches!";
    async function onLoad() {
      // if (!isAuthenticated) {
      //   return;
      // }

      try {
        const athletes = await loadAthletes();
        setAthletes(athletes);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadAthletes() {
    return API.get("esp", "/athletes/new");
  }

  function renderAthleteList(athletes) {
    return (
      <>
        {athletes.map(
          ({
            espId,
            nameFirst,
            nameLast,
            seoName,
            primaryPosition,
            graduationYear,
            gender,
            addrState,
            picture,
          }) => (
            <Col xs={12} xl={4} className="card-col" key={seoName}>
              <Card>
                <span className="card-img-top">
                  {picture ? (
                    <Card.Img
                      variant="top"
                      className="img-fluid"
                      src={config.UPLOAD_URL + picture}
                    />
                  ) : gender && gender === "Female" ? (
                    <Card.Img
                      variant="top"
                      className="img-fluid"
                      src="/images/soccer-player-female-silhouette.png"
                    />
                  ) : gender && gender === "Male" ? (
                    <Card.Img
                      variant="top"
                      className="img-fluid"
                      src="/images/soccer-player-male-silhouette.png"
                    />
                  ) : (
                    <Card.Img
                      variant="top"
                      className="img-fluid"
                      src="/images/soccer-player-silhouette.png"
                    />
                  )}
                </span>
                <Card.Body>
                  <Card.Title>
                    {nameFirst}&nbsp;{nameLast}
                  </Card.Title>
                  <Card.Text>
                    {primaryPosition}
                    <br />
                    {graduationYear}
                    <br />
                    {addrState}
                  </Card.Text>
                  <Button
                    variant="primary"
                    className="card-footer-btn"
                    href={"/athlete/" + seoName}
                  >
                    View Profile
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          )
        )}
      </>
    );
  }

  function renderLander(athletes) {
    return (
      <div className="lander">
        <h3>Welcome to EliteSoccerPlayers.com! </h3>
        {!isAuthenticated && (
          <h4>
            Sign up and create your recruiting profile now! It is quick, easy
            and FREE!
          </h4>
        )}
        <br />
        <h1>Featured Soccer Players</h1>
        <Container>
          <Row className="card-row">
            {(isLoading || !athletes) && (
              <>
                <br />
                <h3>&nbsp;&nbsp;Loading...</h3>
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            {!isLoading && renderAthleteList(athletes)}
          </Row>
        </Container>
      </div>
    );
  }

  return <div className="Admin">{renderLander(athletes)}</div>;
}
