import React, { useRef, useState, useEffect } from "react";
import AWS from "aws-sdk";
import { API } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import { s3Upload, s3Delete } from "../lib/awsLib";
import config from "../config";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { onError } from "../lib/errorLib";
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./AthleteEdit.css";
import { randomUUID } from "crypto";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export default function AthleteEdit() {
  const { id } = useParams();
  const { isAuthenticated, userId } = useAppContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const isAddMode = false;
  const file = useRef(null);
  const [athlete, setAthlete] = useState();
  const [highlightShow, setHighlightShow] = useState(false);
  const [highlightEspId, setHighlightEspId] = useState("");
  const [highlights, setHighlights] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamShow, setTeamShow] = useState(false);
  const [teamEspId, setTeamEspId] = useState("");
  const [schedule, setSchedule] = useState([]);
  const [scheduleShow, setScheduleShow] = useState(false);
  const [scheduleEspId, setScheduleEspId] = useState("");
  const [files, setFiles] = useState([]);
  const [filesOrig, setFilesOrig] = useState([]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    setValue: setValue2,
  } = useForm();

  const {
    register: register3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
    setValue: setValue3,
  } = useForm();

  const {
    register: register4,
    formState: { errors: errors4 },
    handleSubmit: handleSubmit4,
    setValue: setValue4,
  } = useForm();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    function loadAthlete() {
      if (userId) {
        return API.get("esp", `/athlete/edit/${userId}`);
      } else {
        return API.get("esp", `/athlete/edit/${id}`);
      }
    }

    function loadHighlights() {
      return API.get("esp", `/athlete/highlights/${userId}`);
    }

    function loadTeams() {
      return API.get("esp", `/athlete/teams/${userId}`);
    }

    function loadSchedule() {
      return API.get("esp", `/athlete/schedule/${userId}`);
    }

    async function onLoad() {
      try {
        setIsLoading(true);
        if (!isAddMode) {
          await loadAthlete().then((athlete) => {
            const fields = [
              "espId",
              "espKey",
              "academicAchievements",
              "act",
              "address",
              "bio",
              "birthDate",
              "city",
              "classRank",
              "clubId",
              "collegeGradYear",
              "collegeName",
              "displayOrder",
              "email",
              "facebook",
              "gender",
              "gpa",
              "graduationYear",
              "guestPlayer",
              "height",
              "highSchooGradYear",
              "highSchoolName",
              "instagram",
              "jerseyNumber",
              "nameFirst",
              "nameLast",
              "otherActivities",
              "phone",
              "picture",
              "pictureFiles",
              "primaryPosition",
              "primaryGuardianEmail",
              "primaryGuardianName",
              "primaryGuardianPhone",
              "primaryGuardianRelationship",
              "recruitmentStatus",
              "sat",
              "secondaryGuardianEmail",
              "secondaryGuardianName",
              "secondaryGuardianPhone",
              "secondaryGuardianRelationship",
              "secondryPosition",
              "seoName",
              "snapchat",
              "addrState",
              "teamId",
              "teamId2",
              "tiktok",
              "twitter",
              "weight",
              "zip",
            ];
            fields.forEach((field) => setValue(field, athlete[field]));
            setAthlete(athlete);
            if (athlete) {
              document.title =
                "EliteSoccerPlayers.com - Profile - " +
                athlete.nameFirst +
                " " +
                athlete.nameLast;
            }
            setFilesOrig(athlete.pictureFiles);
            const highlightFields = [
              "espId",
              "highlightEspId",
              "highlightName",
              "highlightLink",
              "highlightDisplayOrder",
            ];

            highlightFields.forEach((field) =>
              setValue2(field, athlete[field])
            );
            const teamFields = [
              "espId",
              "teamEspId",
              "teamDisplayName",
              "teamJerseyNumber",
              "teamDateStart",
              "teamDateEnd",
              "teamOrgName",
              "teamPrimaryPosition",
              "teamSecondryPosition",
              "teamCoachName",
              "teamEmail",
              "teamPhone",
              "teamLink",
              "teamScheduleLink",
              "teamAchievements",
            ];
            teamFields.forEach((field) => setValue3(field, athlete[field]));
            const scheduleFields = [
              "espId",
              "scheduleEspId",
              "scheduleDisplayName",
              "scheduleDateStart",
              "scheduleCity",
              "scheduleAddrState",
              "scheduleLink",
            ];
            scheduleFields.forEach((field) => setValue4(field, athlete[field]));
          });

          const highlights = await loadHighlights();
          setHighlights(highlights);

          const teams = await loadTeams();
          setTeams(teams);

          const schedule = await loadSchedule();
          setSchedule(schedule);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);

        onError(e);
      }
    }

    onLoad();
  }, [
    isAuthenticated,
    isAddMode,
    setValue,
    setValue2,
    setValue3,
    setValue4,
    userId,
    id,
  ]);

  function formatFilename(str) {
    return str.substring(str.lastIndexOf("--") + 2);
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  function saveAthlete(athlete) {
    return API.put("esp", `/athlete/update/${userId}`, {
      body: athlete,
    });
  }

  function saveHighlight(highlight) {
    return API.put("esp", `/athlete/highlight/update/${userId}`, {
      body: highlight,
    });
  }

  function deleteHighlight(id) {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (!confirmed) {
      return;
    }
    setHighlightShow(false);
    API.del("esp", `/athlete/highlight/delete/${id}`).then((res) => {
      setHightlighData({
        highlightShow: false,
        highlightEspId: "",
        highlightName: "",
        highlightLink: "",
        highlightDisplayOrder: "",
      });

      history.go(0);
    });
  }

  function setHightlighData(data) {
    setHighlightShow(data.highlightShow);
    setHighlightEspId(data.highlightEspId);
    setValue2("highlightEspId", data.highlightEspId);
    setValue2("highlightName", data.highlightName);
    setValue2("highlightLink", data.highlightLink);
    setValue2("highlightDisplayOrder", data.highlightDisplayOrder);
  }

  function onHighlightSubmit(data) {
    setHighlightShow(false);
    if (!data.highlightEspId) {
      saveHighlight({
        espId: randomUUID,
        espKey: userId,
        displayName: data.highlightName,
        link: data.highlightLink,
        displayOrder: data.highlightDisplayOrder,
      }).then((res) => {
        history.go(0);
      });
    } else {
      saveHighlight({
        espId: data.highlightEspId,
        espKey: userId,
        displayName: data.highlightName,
        link: data.highlightLink,
        displayOrder: data.highlightDisplayOrder,
      }).then((res) => {
        history.go(0);
      });
    }
  }

  function saveTeam(team) {
    return API.put("esp", `/athlete/team/update/${userId}`, {
      body: team,
    });
  }

  function deleteTeam(id) {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (!confirmed) {
      return;
    }

    setTeamShow(false);
    API.del("esp", `/athlete/team/delete/${id}`).then((res) => {
      setTeamData({
        teamShow: false,
        teamEspId: "",
        teamDisplayName: "",
        teamJerseyNumber: "",
        teamDateStart: "",
        teamDateEnd: "",
        teamOrgName: "",
        teamPrimaryPosition: "",
        teamSecondryPosition: "",
        teamCoachName: "",
        teamEmail: "",
        teamPhone: "",
        teamLink: "",
        teamScheduleLink: "",
        teamAchievements: "",
      });

      history.go(0);
    });
  }

  function setTeamData(data) {
    setTeamShow(data.teamShow);
    setTeamEspId(data.teamEspId);
    setValue3("teamEspId", data.teamEspId);
    setValue3("teamDisplayName", data.teamDisplayName);
    setValue3("teamJerseyNumber", data.teamJerseyNumber);
    setValue3("teamDateStart", data.teamDateStart);
    setValue3("teamDateEnd", data.teamDateEnd);
    setValue3("teamOrgName", data.teamOrgName);
    setValue3("teamPrimaryPosition", data.teamPrimaryPosition);
    setValue3("teamSecondryPosition", data.teamSecondryPosition);
    setValue3("teamCoachName", data.teamCoachName);
    setValue3("teamEmail", data.teamEmail);
    setValue3("teamPhone", data.teamPhone);
    setValue3("teamLink", data.teamLink);
    setValue3("teamScheduleLink", data.teamScheduleLink);
    setValue3("teamAchievements", data.teamAchievements);
  }

  function onTeamSubmit(data) {
    console.log("Save Team:", data);
    setTeamShow(false);
    if (!data.teamEspId) {
      saveTeam({
        espId: randomUUID,
        espKey: userId,
        displayName: data.teamDisplayName,
        jerseyNumber: data.teamJerseyNumber,
        dateStart: data.teamDateStart,
        dateEnd: data.teamDateEnd,
        orgName: data.teamOrgName,
        primaryPosition: data.teamPrimaryPosition,
        secondryPosition: data.teamSecondryPosition,
        coachName: data.teamCoachName,
        email: data.teamEmail,
        phone: data.teamPhone,
        link: data.teamLink,
        scheduleLink: data.teamScheduleLink,
        achievements: data.teamAchievements,
      }).then((res) => {
        history.go(0);
      });
    } else {
      saveTeam({
        espId: data.teamEspId,
        espKey: userId,
        displayName: data.teamDisplayName,
        jerseyNumber: data.teamJerseyNumber,
        dateStart: data.teamDateStart,
        dateEnd: data.teamDateEnd,
        orgName: data.teamOrgName,
        primaryPosition: data.teamPrimaryPosition,
        secondryPosition: data.teamSecondryPosition,
        coachName: data.teamCoachName,
        email: data.teamEmail,
        phone: data.teamPhone,
        link: data.teamLink,
        scheduleLink: data.teamScheduleLink,
        achievements: data.teamAchievements,
      }).then((res) => {
        history.go(0);
      });
    }
  }

  function saveSchedule(schedule) {
    return API.put("esp", `/athlete/schedule/update/${userId}`, {
      body: schedule,
    });
  }

  function deleteSchedule(id) {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (!confirmed) {
      return;
    }
    setScheduleShow(false);
    API.del("esp", `/athlete/schedule/delete/${id}`).then((res) => {
      setScheduleData({
        scheduleShow: false,
        scheduleEspId: "",
        scheduleDisplayName: "",
        scheduleDateStart: "",
        scheduleDateEnd: "",
        scheduleCity: "",
        scheduleAddrState: "",
        scheduleLink: "",
      });

      history.go(0);
    });
  }

  function setScheduleData(data) {
    setScheduleShow(data.scheduleShow);
    setScheduleEspId(data.scheduleEspId);
    setValue4("scheduleEspId", data.scheduleEspId);
    setValue4("scheduleDisplayName", data.scheduleDisplayName);
    setValue4("scheduleDateStart", data.scheduleDateStart);
    setValue4("scheduleDateEnd", data.scheduleDateEnd);
    setValue4("scheduleCity", data.scheduleCity);
    setValue4("scheduleAddrState", data.scheduleAddrState);
    setValue4("scheduleLink", data.scheduleLink);
  }

  function onScheduleSubmit(data) {
    console.log("Save Highlight:", data);
    setScheduleShow(false);
    if (!data.scheduleEspId) {
      saveSchedule({
        espId: randomUUID,
        espKey: userId,
        displayName: data.scheduleDisplayName,
        dateStart: data.scheduleDateStart,
        dateEnd: data.scheduleDateEnd,
        city: data.scheduleCity,
        addrState: data.scheduleAddrState,
        link: data.scheduleLink,
      }).then((res) => {
        history.go(0);
      });
    } else {
      saveSchedule({
        espId: data.scheduleEspId,
        espKey: userId,
        displayName: data.scheduleDisplayName,
        dateStart: data.scheduleDateStart,
        dateEnd: data.scheduleDateEnd,
        city: data.scheduleCity,
        addrState: data.scheduleAddrState,
        link: data.scheduleLink,
      }).then((res) => {
        history.go(0);
      });
    }
  }

  function getImageUrl(attachment) {
    var picUrl = config.UPLOAD_URL + attachment + "?width=462";
    return picUrl;
  }

  function deletePhoto(fileName) {
    const athletePhotos = filesOrig.filter((i) => i !== fileName);
    setFilesOrig(athletePhotos);
  }

  async function onSubmit(data) {
    setIsLoading(true);

    console.log("Save:", data);

    if (!file.current && !data.picture) {
      alert(`Please select a Playing Profile Picture.`);
      return;
    }

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    if (file.current) {
      let prevFileName = data.picture;
      data.picture = await s3Upload(data.espId, file.current);
      await s3Delete(prevFileName);
    }

    if (!Array.isArray(data.pictureFiles)) {
      data.pictureFiles = [];
    }
    if (!Array.isArray(filesOrig)) {
      setFilesOrig([]);
    }

    var p;
    var i;
    //compare data.pictureFiles to filesOrig and delete
    if (data.pictureFiles && data.pictureFiles.length > 0) {
      if (filesOrig && filesOrig.length > 0) {
        for (p = 0; p < data.pictureFiles.length; p++) {
          var exists = false;
          for (i = 0; i < filesOrig.length; i++) {
            if (data.pictureFiles[p] === filesOrig[i]) {
              exists = true;
              break;
            }
          }
          if (!exists) {
            await s3Delete(data.pictureFiles[p]);
            const index = data.pictureFiles.indexOf(data.pictureFiles[p]);
            if (index > -1) {
              data.pictureFiles.splice(index, 1);
            }
          }
        }
      } else {
        for (p = 0; p < data.pictureFiles.length; p++) {
          await s3Delete(data.pictureFiles[p]);
          const index = data.pictureFiles.indexOf(data.pictureFiles[p]);
          if (index > -1) {
            data.pictureFiles.splice(index, 1);
          }
        }
      }
    }

    //Save everything in Files
    if (files && files.length > 0) {
      for (p = 0; p < files.length; p++) {
        var uploadFileName = await s3Upload(data.espId, files[p].file);
        data.pictureFiles.push(uploadFileName);
      }
    }

    saveAthlete(data).then((res) => {
      // Initialize the Amazon Cognito credentials provider
      AWS.config.region = "us-east-1"; // Region
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
      });

      var sns = new AWS.SNS();

      var params = {
        Message:
          "Name: " +
          data.nameFirst +
          " " +
          data.nameLast +
          "\n" +
          " Email: " +
          data.email +
          "\n" +
          " SEO Name: " +
          data.seoName +
          "\n" +
          " Id: " +
          data.espId +
          "\n\n",
        Subject: config.PROFILE_UPDATED_SUBJECT,
        TopicArn: config.CONTACT_US_TOPIC_ARN,
      };

      sns.publish(params, function (err, data) {
        if (err) {
          //console.log(err, err.stack); // an error occurred
        } else {
          //console.log(data); // successful response
        }
      });
      history.push("/dashboard");
    });
  }

  function renderTeamList(teams) {
    return (
      <>
        {teams.map(
          ({
            espId,
            displayName,
            jerseyNumber,
            dateStart,
            dateEnd,
            orgName,
            primaryPosition,
            secondryPosition,
            coachName,
            email,
            phone,
            link,
            scheduleLink,
            achievements,
          }) => (
            <Row className="show-grid" key={espId}>
              <Col xs={12} sm={10} className="card-col">
                <h5>
                  <center>
                    {displayName} - {orgName}
                  </center>
                </h5>
              </Col>
              <Col xs={12} sm={2} className="card-col">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() =>
                    setTeamData({
                      teamShow: true,
                      teamEspId: espId,
                      teamDisplayName: displayName,
                      teamJerseyNumber: jerseyNumber,
                      teamDateStart: dateStart,
                      teamDateEnd: dateEnd,
                      teamOrgName: orgName,
                      teamPrimaryPosition: primaryPosition,
                      teamSecondryPosition: secondryPosition,
                      teamCoachName: coachName,
                      teamEmail: email,
                      teamPhone: phone,
                      teamLink: link,
                      teamScheduleLink: scheduleLink,
                      teamAchievements: achievements,
                    })
                  }
                >
                  Edit
                </Button>
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>Jersey Number:</label>
              </Col>
              <Col xs={12} sm={8} className="card-col">
                {jerseyNumber}
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>Primary Position:</label>
              </Col>
              <Col xs={12} sm={8} className="card-col">
                {primaryPosition}
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>Secondry Pos:</label>
              </Col>
              <Col xs={12} sm={8} className="card-col">
                {secondryPosition}
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>Date Start:</label>
              </Col>
              <Col xs={12} sm={8} className="card-col">
                {dateStart}
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>Date End:</label>
              </Col>
              <Col xs={12} sm={8} className="card-col">
                {dateEnd}
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>Coach Name:</label>
              </Col>
              <Col xs={12} sm={8} className="card-col">
                {coachName}
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>Coach Email:</label>
              </Col>
              <Col xs={12} sm={8} className="card-col">
                {email}
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>Coach Phone:</label>
              </Col>
              <Col xs={12} sm={8} className="card-col">
                {phone}
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>Link:</label>
              </Col>
              <Col xs={12} sm={8} className="card-col">
                {link && link.length > 32 ? link.substring(0,30) + '...' : link}
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>Schedule Link:</label>
              </Col>
              <Col xs={12} sm={8} className="card-col">
                {scheduleLink && scheduleLink.length > 32 ? scheduleLink.substring(0,30) + '...' : scheduleLink}
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>Achievements:</label>
              </Col>
              <Col xs={12} sm={8} className="card-col">
                {achievements}
              </Col>
              <Col xs={12}>&nbsp;</Col>
            </Row>
          )
        )}
      </>
    );
  }

  function TeamModal(props) {
    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
        <form onSubmit={handleSubmit3(onTeamSubmit)}>
          <input hidden {...register3("teamEspId", {})} />
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Team</Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Col xs={12} xl={4}>
                  <label>*Club Name:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    {...register3("teamOrgName", {
                      required: true,
                      maxLength: 75,
                    })}
                  />
                  <p className="validation">
                    {errors3.teamOrgName?.type === "required" &&
                      "Field is required"}
                    {errors3.teamOrgName?.type === "maxLength" &&
                      "Field must be 75 characters or less"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>*Team Name:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    {...register3("teamDisplayName", {
                      required: true,
                      maxLength: 75,
                    })}
                  />
                  <p className="validation">
                    {errors3.teamDisplayName?.type === "required" &&
                      "Field is required"}
                    {errors3.teamDisplayName?.type === "maxLength" &&
                      "Field must be 75 characters or less"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>Jersey Number:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    {...register3("teamJerseyNumber", {
                      pattern: /^[0-9]+$/i,
                      maxLength: 3,
                    })}
                  />
                  <p className="validation">
                    {errors3.teamJerseyNumber?.type === "pattern" &&
                      "Field must be numbers only"}
                    {errors3.teamJerseyNumber?.type === "maxLength" &&
                      "Field must be 3 numbers or less"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>*Start Date:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    type="date"
                    {...register3("teamDateStart", { required: true })}
                  />
                  <p className="validation">
                    {errors3.teamDateStart?.type === "pattern" &&
                      "Please correct format"}
                    {errors3.teamDateStart?.type === "required" &&
                      "Field is required"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>End Date:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input type="date" {...register3("teamDateEnd", {})} />
                  <p className="validation">
                    {errors3.teamDateEnd?.type === "pattern" &&
                      "Please correct format"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>*Primary Position:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <select
                    {...register3("teamPrimaryPosition", { required: true })}
                  >
                    <option value="Goalkeeper (1)">Goalkeeper (1)</option>
                    <option value="Outside Back (2,3)">
                      Outside Back (2,3)
                    </option>
                    <option value="Center Back (4, 5)">
                      Center Back (4, 5)
                    </option>
                    <option value="Defending/Holding Midfielder (6)">
                      Defending/Holding Midfielder (6)
                    </option>
                    <option value="Winger/Midfielder (7, 11)">
                      Winger/Midfielder (7, 11)
                    </option>
                    <option value="Central/Box-to-Box Midfielder (8)">
                      Central/Box-to-Box Midfielder (8)
                    </option>
                    <option value="Striker (9)">Striker (9)</option>
                    <option value="Attacking Midfielder (10)">
                      Attacking Midfielder (10)
                    </option>
                  </select>
                  <p className="validation">
                    {errors3.teamPrimaryPosition?.type === "required" &&
                      "Field is required"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>Secondary Position:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <select {...register3("teamSecondryPosition", {})}>
                    <option value="Goalkeeper (1)">Goalkeeper (1)</option>
                    <option value="Outside Back (2,3)">
                      Outside Back (2,3)
                    </option>
                    <option value="Center Back (4, 5)">
                      Center Back (4, 5)
                    </option>
                    <option value="Defending/Holding Midfielder (6)">
                      Defending/Holding Midfielder (6)
                    </option>
                    <option value="Winger/Midfielder (7, 11)">
                      Winger/Midfielder (7, 11)
                    </option>
                    <option value="Central/Box-to-Box Midfielder (8)">
                      Central/Box-to-Box Midfielder (8)
                    </option>
                    <option value="Striker (9)">Striker (9)</option>
                    <option value="Attacking Midfielder (10)">
                      Attacking Midfielder (10)
                    </option>
                    <option value="No Secondary Position">
                      No Secondary Position
                    </option>
                  </select>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>Coach Name:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    {...register3("teamCoachName", {
                      maxLength: 75,
                    })}
                  />
                  <p className="validation">
                    {errors3.teamCoachName?.type === "maxLength" &&
                      "Field must be 75 characters or less"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>Coach Email:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    {...register3("teamEmail", {
                      maxLength: 100,
                    })}
                  />
                  <p className="validation">
                    {errors3.teamEmail?.type === "maxLength" &&
                      "Field must be 100 characters or less"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>Coach Phone:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    {...register3("teamPhone", {
                      maxLength: 100,
                    })}
                  />
                  <p className="validation">
                    {errors3.teamPhone?.type === "maxLength" &&
                      "Field must be 100 characters or less"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>Team Link:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    {...register3("teamLink", {
                      maxLength: 125,
                    })}
                  />
                  <p className="validation">
                    {errors3.teamLink?.type === "maxLength" &&
                      "Field must be 125 characters or less"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>Schedule Link:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    {...register3("teamScheduleLink", {
                      maxLength: 125,
                    })}
                  />
                  <p className="validation">
                    {errors3.teamScheduleLink?.type === "maxLength" &&
                      "Field must be 125 characters or less"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>Achievements:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <textarea
                    rows="2"
                    cols="50"
                    {...register3("teamAchievements", {
                      maxLength: 500,
                    })}
                  />
                  <p className="validation">
                    {errors3.teamAchievements?.type === "maxLength" &&
                      "Field must be 500 characters or less"}
                  </p>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">Save</Button>
            <Button variant="danger" onClick={() => deleteTeam(teamEspId)}>
              Delete
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  function renderScheduleList(schedule) {
    return (
      <>
        {schedule.map(
          ({
            espId,
            displayName,
            dateStart,
            dateEnd,
            city,
            addrState,
            link,
          }) => (
            <Row className="show-grid" key={espId}>
              <Col xs={12} sm={10} className="card-col">
                <h5>
                  <center>{displayName}</center>
                </h5>
              </Col>
              <Col xs={12} sm={2}>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() =>
                    setScheduleData({
                      scheduleShow: true,
                      scheduleEspId: espId,
                      scheduleDisplayName: displayName,
                      scheduleDateStart: dateStart,
                      scheduleDateEnd: dateEnd,
                      scheduleCity: city,
                      scheduleAddrState: addrState,
                      scheduleLink: link,
                    })
                  }
                >
                  Edit
                </Button>
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>Start Date:</label>
              </Col>
              <Col xs={12} sm={8} className="card-col">
                {dateStart}
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>End Date:</label>
              </Col>
              <Col xs={12} sm={8} className="card-col">
                {dateEnd}
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>City, State:</label>
              </Col>
              <Col xs={12} sm={8} className="card-col">
                {city}
                {", "}
                {addrState}
              </Col>
              <Col xs={12} sm={4} className="card-col">
                <label>Event Link:</label>
              </Col>
              <Col xs={12} sm={6} className="card-col">
                <a href={link} target="_blank" rel="noreferrer">
                  {link && link.length > 32 ? link.substring(0,30) + '...' : link}
                </a>
              </Col>
              <Col xs={12}>&nbsp;</Col>
            </Row>
          )
        )}
      </>
    );
  }

  function ScheduleModal(props) {
    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
        <form onSubmit={handleSubmit4(onScheduleSubmit)}>
          <input hidden {...register4("scheduleEspId", {})} />
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Schedule
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Col xs={12} xl={4}>
                  <label>*Event Name:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    {...register4("scheduleDisplayName", {
                      required: true,
                      maxLength: 100,
                    })}
                  />
                  <p className="validation">
                    {errors4.scheduleDisplayName?.type === "required" &&
                      "Field is required"}
                    {errors4.scheduleDisplayName?.type === "maxLength" &&
                      "Field must be 100 characters or less"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>*Start Date:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    type="date"
                    {...register4("scheduleDateStart", {
                      required: true,
                    })}
                  />
                  <p className="validation">
                    {errors4.scheduleDateStart?.type === "required" &&
                      "Field is required"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>*End Date:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    type="date"
                    {...register4("scheduleDateEnd", {
                      required: true,
                    })}
                  />
                  <p className="validation">
                    {errors4.scheduleDateEnd?.type === "required" &&
                      "Field is required"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>*City:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    {...register4("scheduleCity", {
                      required: true,
                      maxLength: 100,
                    })}
                  />
                  <p className="validation">
                    {errors4.scheduleCity?.type === "required" &&
                      "Field is required"}
                    {errors4.scheduleCity?.type === "maxLength" &&
                      "Field must be 100 characters or less"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>*State:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <select
                    {...register4("scheduleAddrState", { required: true })}
                  >
                    <option value="---">---</option>
                    <option value="Alabama">Alabama</option>
                    <option value="Alaska">Alaska</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Arkansas">Arkansas</option>
                    <option value="California">California</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Connecticut">Connecticut</option>
                    <option value="Delaware">Delaware</option>
                    <option value="District of Columbia">
                      District of Columbia
                    </option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Guam">Guam</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Iowa">Iowa</option>
                    <option value="Kansas">Kansas</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Louisiana">Louisiana</option>
                    <option value="Maine">Maine</option>
                    <option value="Maryland">Maryland</option>
                    <option value="Massachusetts">Massachusetts</option>
                    <option value="Michigan">Michigan</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Mississippi">Mississippi</option>
                    <option value="Missouri">Missouri</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="Nevada">Nevada</option>
                    <option value="New Hampshire">New Hampshire</option>
                    <option value="New Jersey">New Jersey</option>
                    <option value="New Mexico">New Mexico</option>
                    <option value="New York">New York</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="Northern Marianas Islands">
                      Northern Marianas Islands
                    </option>
                    <option value="Ohio">Ohio</option>
                    <option value="Oklahoma">Oklahoma</option>
                    <option value="Oregon">Oregon</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Rhode Island">Rhode Island</option>
                    <option value="South Carolina">South Carolina</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Tennessee">Tennessee</option>
                    <option value="Texas">Texas</option>
                    <option value="Utah">Utah</option>
                    <option value="Vermont">Vermont</option>
                    <option value="Virginia">Virginia</option>
                    <option value="Virgin Islands">Virgin Islands</option>
                    <option value="Washington">Washington</option>
                    <option value="West Virginia">West Virginia</option>
                    <option value="Wisconsin">Wisconsin</option>
                    <option value="Wyoming">Wyoming</option>
                  </select>
                  <p className="validation">
                    {errors4.scheduleAddrState?.type === "required" &&
                      "Field is required"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>Link:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    {...register4("scheduleLink", {
                      maxLength: 125,
                    })}
                  />
                  <p className="validation">
                    {errors4.scheduleLink?.type === "maxLength" &&
                      "Field must be 125 characters or less"}
                  </p>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">Save</Button>
            <Button
              variant="danger"
              onClick={() => deleteSchedule(scheduleEspId)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  function renderHighlightList(highlights) {
    return (
      <>
        {highlights.map(({ espId, displayName, link, displayOrder }) => (
          <Row className="show-grid" key={espId}>
            <Col xs={12} sm={10} className="card-col">
              <a href={link} target="_blank" rel="noreferrer">
                {displayName}
              </a>
            </Col>
            <Col xs={12} sm={2} className="card-col">
              <Button
                variant="primary"
                size="sm"
                onClick={() =>
                  setHightlighData({
                    highlightShow: true,
                    highlightEspId: espId,
                    highlightName: displayName,
                    highlightLink: link,
                    highlightDisplayOrder: displayOrder,
                  })
                }
              >
                Edit
              </Button>
            </Col>
          </Row>
        ))}
      </>
    );
  }

  function HighlightModal(props) {
    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
        <form onSubmit={handleSubmit2(onHighlightSubmit)}>
          <input hidden {...register2("highlightEspId", {})} />
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Highlights &amp; Other Profiles
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Col xs={12} xl={4}>
                  <label>*Name:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    {...register2("highlightName", {
                      required: true,
                      maxLength: 75,
                    })}
                  />
                  <p className="validation">
                    {errors2.highlightName?.type === "required" &&
                      "Field is required"}
                    {errors2.highlightLink?.type === "maxLength" &&
                      "Field must be 75 characters or less"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>*Link:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <input
                    {...register2("highlightLink", {
                      required: true,
                      maxLength: 125,
                    })}
                  />
                  <p className="validation">
                    {errors2.highlightLink?.type === "required" &&
                      "Field is required"}
                    {errors2.highlightLink?.type === "maxLength" &&
                      "Field must be 125 numbers or less"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={4}>
                  <label>Display Order:</label>
                </Col>
                <Col xs={12} xl={8}>
                  <select
                    {...register2("highlightDisplayOrder", { required: true })}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="90">9</option>
                    <option value="91">10</option>
                  </select>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">Save</Button>
            <Button
              variant="danger"
              onClick={() => deleteHighlight(highlightEspId)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  function renderPhotos(photos) {
    return [{}].concat(photos).map((photo, i) =>
      i !== 0 ? (
        <Col key={i} xs={6} md={3}>
          <a
            href={getImageUrl(photo)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              key={i}
              src={getImageUrl(photo)}
              variant="top"
              className="img-fluid img-max-h-300"
              alt={photo}
              responsive="true"
            />
          </a>
          <br />
          <Button
            type="button"
            onClick={deletePhoto.bind(this, photo)}
            variant="danger"
            size="sm"
            className="buttonSpace photo-delete-btn"
          >
            Delete
          </Button>
        </Col>
      ) : (
        ""
      )
    );
  }

  return (
    <div className="AthleteEdit container">
      {(isLoading || !athlete) && (
        <>
          <br />
          <h3>&nbsp;&nbsp;Loading...</h3>
          <br />
          <br />
          <br />
          <br />
        </>
      )}
      {!isLoading && athlete && (
        <>
          <div className="lander">
            <h1>Edit My Recruiting Profile</h1>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" {...register("espKeyOrig")} />
            <input type="hidden" {...register("pictureFiles")} />
            <input type="hidden" {...register("displayOrder")} />
            <Row className="section">
              <Col xs={12} xl={4}>
                {athlete.picture ? (
                  <Image
                    src={config.UPLOAD_URL + athlete.picture}
                    className="img-sz"
                    alt="ESP"
                  />
                ) : (
                  <Image
                    src="/images/esp_logo_green.jpeg"
                    sizes="100%"
                    alt="ESP"
                  />
                )}
                {athlete.picture && <p>{formatFilename(athlete.picture)}</p>}
                <br />
                <span>
                  *Select Your Playing Profile Picture (jpg,gif,png,svg):
                </span>
                <input onChange={handleFileChange} type="file" />
              </Col>
              <Col xs={12} xl={8}>
                <Row className="show-grid">
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label htmlFor="nameFirst">*First Name:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("nameFirst", {
                        pattern: /^[A-Za-z ]+$/i,
                        required: true,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.nameFirst?.type === "required" &&
                        "Field is required"}
                      {errors.nameFirst?.type === "pattern" &&
                        "Field must be letters only"}
                      {errors.nameFirst?.type === "maxLength" &&
                        "Field must be 35 numbers or less"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label htmlFor="nameLast">*Last Name:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("nameLast", {
                        pattern: /^[A-Za-z ]+$/i,
                        required: true,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.nameLast?.type === "required" &&
                        "Field is required"}
                      {errors.nameLast?.type === "pattern" &&
                        "Please correct format"}
                      {errors.nameLast?.type === "maxLength" &&
                        "Field must be 35 numbers or less"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label htmlFor="graduationYear">*Graduation Year:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select {...register("graduationYear", { required: true })}>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                      <option value="2030">2031</option>
                      <option value="2030">2032</option>
                    </select>
                    <p className="validation">
                      {errors.graduationYear?.type === "required" &&
                        "Graduatin year is required"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label>*Primary Position:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select
                      {...register("primaryPosition", { required: true })}
                    >
                      <option value="Goalkeeper (1)">Goalkeeper (1)</option>
                      <option value="Outside Back (2,3)">
                        Outside Back (2,3)
                      </option>
                      <option value="Center Back (4, 5)">
                        Center Back (4, 5)
                      </option>
                      <option value="Defensive Midfielder (6)">
                        Defensive Midfielder (6)
                      </option>
                      <option value="Winger (7, 11)">Winger (7, 11)</option>
                      <option value="Central Midfielder (8)">
                        Central Midfielder (8)
                      </option>
                      <option value="Striker (9)">Striker (9)</option>
                      <option value="Attacking Midfielder (10)">
                        Attacking Midfielder (10)
                      </option>
                    </select>
                    <p className="validation">
                      {errors.primaryPosition?.type === "required" &&
                        "Field is required"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label>Secondry Position:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select {...register("secondryPosition", {})}>
                      <option value="No Secondary Position">
                        No Secondary Position
                      </option>
                      <option value="Goalkeeper (1)">Goalkeeper (1)</option>
                      <option value="Outside Back (2,3)">
                        Outside Back (2,3)
                      </option>
                      <option value="Center Back (4, 5)">
                        Center Back (4, 5)
                      </option>
                      <option value="Defensive Midfielder (6)">
                        Defensive Midfielder (6)
                      </option>
                      <option value="Winger (7, 11)">Winger (7, 11)</option>
                      <option value="Central Midfielder (8)">
                        Central Midfielder (8)
                      </option>
                      <option value="Striker (9)">Striker (9)</option>
                      <option value="Attacking Midfielder (10)">
                        Attacking Midfielder (10)
                      </option>
                    </select>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label>Height:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select {...register("height", {})}>
                      <option value="4' 6&quot;">4&apos; 6&quot;</option>
                      <option value="4' 7&quot;">4&apos; 7&quot;</option>
                      <option value="4' 8&quot;">4&apos; 8&quot;</option>
                      <option value="4' 9&quot;">4&apos; 9&quot;</option>
                      <option value="4' 10&quot;">4&apos; 10&quot;</option>
                      <option value="4' 11&quot;">4&apos; 11&quot;</option>
                      <option value="5' 0&quot;">5&apos; 0&quot;</option>
                      <option value="5' 1&quot;">5&apos; 1&quot;</option>
                      <option value="5' 2&quot;">5&apos; 2&quot;</option>
                      <option value="5' 3&quot;">5&apos; 3&quot;</option>
                      <option value="5' 4&quot;">5&apos; 4&quot;</option>
                      <option value="5' 5&quot;">5&apos; 5&quot;</option>
                      <option value="5' 6&quot;">5&apos; 6&quot;</option>
                      <option value="5' 7&quot;">5&apos; 7&quot;</option>
                      <option value="5' 8&quot;">5&apos; 8&quot;</option>
                      <option value="5' 9&quot;">5&apos; 9&quot;</option>
                      <option value="5' 10&quot;">5&apos; 10&quot;</option>
                      <option value="5' 11&quot;">5&apos; 11&quot;</option>
                      <option value="6' 0&quot;">6&apos; 0&quot;</option>
                      <option value="6' 1&quot;">6&apos; 1&quot;</option>
                      <option value="6' 2&quot;">6&apos; 2&quot;</option>
                      <option value="6' 3&quot;">6&apos; 3&quot;</option>
                      <option value="6' 4&quot;">6&apos; 4&quot;</option>
                      <option value="6' 5&quot;">6&apos; 5&quot;</option>
                      <option value="6' 6&quot;">6&apos; 6&quot;</option>
                      <option value="6' 7&quot;">6&apos; 7&quot;</option>
                      <option value="6' 8&quot;">6&apos; 8&quot;</option>
                      <option value="6' 9&quot;">6&apos; 9&quot;</option>
                      <option value="6' 10&quot;">6&apos; 10&quot;</option>
                      <option value="6' 11&quot;">6&apos; 11&quot;</option>
                      <option value="7' 0&quot;">7&apos; 0&quot;</option>
                      <option value="7' 1&quot;">7&apos; 1&quot;</option>
                      <option value="7' 2&quot;">7&apos; 2&quot;</option>
                      <option value="7' 3&quot;">7&apos; 3&quot;</option>
                    </select>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label>Weight:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("weight", {
                        pattern: /^[0-9]+$/i,
                        maxLength: 3,
                      })}
                    />
                    <p className="validation">
                      {errors.weight?.type === "pattern" &&
                        "Please correct format"}
                      {errors.weight?.type === "maxLength" &&
                        "Field must be 3 numbers or less"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label>Gender:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select {...register("gender", {})}>
                      <option value=""></option>
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                    </select>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label>Jersey Number:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("jerseyNumber", {
                        pattern: /^[0-9]+$/i,
                        maxLength: 3,
                      })}
                    />
                    <p className="validation">
                      {errors.jerseyNumber?.type === "pattern" &&
                        "Field must be numbers only"}
                      {errors.jerseyNumber?.type === "maxLength" &&
                        "Field must be 3 numbers or less"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label>*Recruitment Status:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select
                      {...register("recruitmentStatus", { required: true })}
                    >
                      <option value="Open">Open</option>
                      <option value="Committed">Committed</option>
                      <option value="No Looking">Not Looking</option>
                    </select>
                    <p className="validation">
                      {errors.recruitmentStatus?.type === "required" &&
                        "Field is required"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Highlights &amp; Other Profiles</b>
                </h4>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() =>
                    setHightlighData({
                      highlightShow: true,
                      highlightEspId: "",
                      highlightName: "",
                      highlightLink: "",
                      highlightDisplayOrder: "",
                    })
                  }
                >
                  Add New
                </Button>
              </Col>
              <Col xs={12}>
                {highlights && renderHighlightList(highlights)}
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Bio</b>
                </h4>
              </Col>
              <Col xs={12}>
                <textarea
                  rows="2"
                  cols="52"
                  className="textarea"
                  {...register("bio", {
                    maxLength: 5000,
                  })}
                />
                <p className="validation">
                  {errors.bio?.type === "maxLength" &&
                    "Field must be 5000 characters or less"}
                </p>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Teams</b>
                </h4>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() =>
                    setTeamData({
                      teamShow: true,
                      teamEspId: "",
                      teamDisplayName: "",
                      teamJerseyNumber: "",
                      teamDateStart: "",
                      teamDateEnd: "",
                      teamOrgName: "",
                      teamPrimaryPosition: "",
                      teamSecondryPosition: "",
                      teamCoachName: "",
                      teamEmail: "",
                      teamPhone: "",
                      teamLink: "",
                      teamScheduleLink: "",
                      teamAchievements: "",
                    })
                  }
                >
                  Add New
                </Button>
              </Col>
              <Col xs={12}>
                {teams && renderTeamList(teams)}
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Schedule</b>
                </h4>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() =>
                    setScheduleData({
                      scheduleShow: true,
                      scheduleEspId: "",
                      scheduleDisplayName: "",
                      scheduleDateStart: "",
                      scheduleDateEnd: "",
                      scheduleCity: "",
                      scheduleAddrState: "",
                      scheduleLink: "",
                    })
                  }
                >
                  Add New
                </Button>
              </Col>
              <Col xs={12}>
                <>{schedule && renderScheduleList(schedule)}</>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Academic</b>
                </h4>
              </Col>
              <Col xs={12}>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label htmlFor="highSchoolName">High School Name:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("highSchoolName", {
                        maxLength: 100,
                      })}
                    />
                    <p className="validation">
                      {errors.highSchoolName?.type === "pattern" &&
                        "Please correct format"}
                      {errors.highSchoolName?.type === "maxLength" &&
                        "Field must be 100 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>HS Grad Year:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("highSchooGradYear", {
                        pattern: /^[0-9]+$/i,
                        maxLength: 4,
                      })}
                    />
                    <p className="validation">
                      {errors.highSchooGradYear?.type === "pattern" &&
                        "Please correct format"}
                      {errors.highSchooGradYear?.type === "maxLength" &&
                        "Field must be 4 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>College Name:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("collegeName", {
                        maxLength: 100,
                      })}
                    />
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>College Grad Year:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("collegeGradYear", {
                        pattern: /^[0-9]+$/i,
                        maxLength: 4,
                      })}
                    />
                    <p className="collegeGradYear">
                      {errors.collegeGradYear?.type === "pattern" &&
                        "Please correct format"}
                      {errors.collegeGradYear?.type === "maxLength" &&
                        "Field must be 4 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>GPA:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("gpa", {
                        pattern: /^[A-Za-z0-9]+\.?/i,
                        maxLength: 5,
                      })}
                    />
                    <p className="validation">
                      {errors.gpa?.type === "pattern" &&
                        "Please correct format"}
                      {errors.gpa?.type === "maxLength" &&
                        "Field must be 5 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>SAT:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("sat", {
                        pattern: /^[A-Za-z0-9]+\.?/i,
                        maxLength: 5,
                      })}
                    />
                    <p className="validation">
                      {errors.sat?.type === "pattern" &&
                        "Please correct format"}
                      {errors.sat?.type === "maxLength" &&
                        "Field must be 5 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>ACT:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("act", {
                        pattern: /^[A-Za-z0-9]+\.?/i,
                        maxLength: 4,
                      })}
                    />
                    <p className="validation">
                      {errors.act?.type === "pattern" &&
                        "Please correct format"}
                      {errors.act?.type === "maxLength" &&
                        "Field must be 4 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Class Rank:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("classRank", {
                        pattern: /^[0-9]+$/i,
                        maxLength: 4,
                      })}
                    />
                    <p className="validation">
                      {errors.classRank?.type === "pattern" &&
                        "Please correct format"}
                      {errors.classRank?.type === "maxLength" &&
                        "Field must be 4 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Achievements:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("academicAchievements", {
                        maxLength: 500,
                      })}
                    />
                    <p className="validation">
                      {errors.academicAchievements?.type === "maxLength" &&
                        "Field must be 500 characters or less"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Other Activities</b>
                </h4>
              </Col>
              <Col xs={12}>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Other Activities:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("otherActivities", {
                        maxLength: 500,
                      })}
                    />
                    <p className="validation">
                      {errors.otherActivities?.type === "maxLength" &&
                        "Field must be 500 characters or less"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Contact Information</b>
                </h4>
              </Col>
              <Col xs={12}>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>*Email:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Please enter a valid email",
                        },
                      })}
                    />
                    <p className="validation">
                      {errors.email?.type === "required" && "Field is required"}
                      {errors.email?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Phone:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("phone", {
                        minLength: 10,
                        maxLength: 17,
                      })}
                    />
                    <p className="validation">
                      {errors.phone?.type === "pattern" &&
                        "Please correct format"}
                      {errors.phone?.type === "maxLength" &&
                        "Field must be 17 characters or less"}
                      {errors.phone?.type === "minLength" &&
                        "Field must be 10 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Address:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("address", {
                        pattern: /^[A-Za-z0-9]+\.?/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.address?.type === "pattern" &&
                        "Please correct format"}
                      {errors.address?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>*City:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("city", {
                        required: true,
                        pattern: /^[A-Za-z ]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.city?.type === "required" && "Field is required"}
                      {errors.city?.type === "pattern" &&
                        "Please correct format"}
                      {errors.city?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>*State:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select {...register("addrState", { required: true })}>
                      <option value="---">---</option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District of Columbia">
                        District of Columbia
                      </option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Guam">Guam</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Northern Marianas Islands">
                        Northern Marianas Islands
                      </option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Virgin Islands">Virgin Islands</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                    </select>
                    <p className="validation">
                      {errors.addrState?.type === "required" &&
                        "Field is required"}
                      {errors.addrState?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Zip Code:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("zip", {
                        pattern: /^[0-9]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.zip?.type === "pattern" &&
                        "Please correct format"}
                      {errors.zip?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}></Col>
                  <Col xs={12} xl={8}>
                    <b>Primary Guardian</b>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Name:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("primaryGuardianName", {
                        pattern: /^[A-Za-z ]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.primaryGuardianName?.type === "pattern" &&
                        "Please correct format"}
                      {errors.primaryGuardianName?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Relationship:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select {...register("primaryGuardianRelationship", {})}>
                      <option value="Mother">Mother</option>
                      <option value="Father">Father</option>
                      <option value="Parent">Parent</option>
                      <option value="Stepmother">Stepmother</option>
                      <option value="Stepfather">Stepfather</option>
                      <option value="Guardian">Guardian</option>
                      <option value="Other">Other</option>
                    </select>
                    <p className="validation">
                      {errors.primaryGuardianRelationship?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Email:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("primaryGuardianEmail", {
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.primaryGuardianEmail?.type === "pattern" &&
                        "Please correct format"}
                      {errors.primaryGuardianEmail?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Phone:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("primaryGuardianPhone", {
                        minLength: 10,
                        maxLength: 17,
                      })}
                    />
                    <p className="validation">
                      {errors.primaryGuardianPhone?.type === "pattern" &&
                        "Please correct format"}
                      {errors.primaryGuardianPhone?.type === "maxLength" &&
                        "Field must be 17 characters or less"}
                      {errors.primaryGuardianPhone?.type === "minLength" &&
                        "Field must be 10 characters or more"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}></Col>
                  <Col xs={12} xl={8}>
                    <b>Secondary Guardian</b>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Name:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("secondaryGuardianName", {
                        pattern: /^[A-Za-z ]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.secondaryGuardianName?.type === "pattern" &&
                        "Please correct format"}
                      {errors.secondaryGuardianName?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Relationship:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select {...register("secondaryGuardianRelationship")}>
                      <option value="Mother">Mother</option>
                      <option value="Father">Father</option>
                      <option value="Parent">Parent</option>
                      <option value="Stepmother">Stepmother</option>
                      <option value="Stepfather">Stepfather</option>
                      <option value="Guardian">Guardian</option>
                      <option value="Other">Other</option>
                    </select>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Email:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("secondaryGuardianEmail", {
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        maxLength: 100,
                      })}
                    />
                    <p className="validation">
                      {errors.secondaryGuardianEmail?.type === "required" &&
                        "Field is required"}
                      {errors.secondaryGuardianEmail?.type === "pattern" &&
                        "Please correct format"}
                      {errors.secondaryGuardianEmail?.type === "maxLength" &&
                        "Field must be 100 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Phone:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("secondaryGuardianPhone", {
                        minLength: 10,
                        maxLength: 17,
                      })}
                    />
                    <p className="validation">
                      {errors.secondaryGuardianPhone?.type === "maxLength" &&
                        "Field must be 17 characters or less"}
                      {errors.secondaryGuardianPhone?.type === "minLength" &&
                        "Field must be 10 characters or less"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Social Media Account</b>
                </h4>
              </Col>
              <Col xs={12}>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Twiter:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("twitter", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.twitter?.type === "pattern" &&
                        "Please correct format"}
                      {errors.twitter?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Instagram:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("instagram", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.instagram?.type === "pattern" &&
                        "Please correct format"}
                      {errors.instagram?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Facebook:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("facebook", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.facebook?.type === "pattern" &&
                        "Please correct format"}
                      {errors.facebook?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Snapchat:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("snapchat", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.snapchat?.type === "pattern" &&
                        "Please correct format"}
                      {errors.snapchat?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Tiktok:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("tiktok", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.tiktok?.type === "pattern" &&
                        "Please correct format"}
                      {errors.tiktok?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>More Photos</b>
                </h4>
              </Col>
              <Col xs={12}>
                <FilePond
                  files={files}
                  onupdatefiles={setFiles}
                  allowMultiple={true}
                  maxFiles={5}
                  name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
                {filesOrig && filesOrig.length > 0 && (
                  <Row>{renderPhotos(filesOrig)}</Row>
                )}
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Other</b>
                </h4>
              </Col>
              <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label htmlFor="guestPlayer">Guest Playing Opportunities:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select {...register("guestPlayer", { required: false })}>
                      <option value="No">No, I am not interested</option>
                      <option value="Yes">Yes, I am interested</option>
                    </select>
                  </Col>
              <Col xs={12}>&nbsp;</Col>
              <Col xs={12}>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Club Id:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("clubId", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.clubId?.type === "pattern" &&
                        "Please correct format"}
                      {errors.clubId?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Team Id:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("teamId", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.teamId?.type === "pattern" &&
                        "Please correct format"}
                      {errors.teamId?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Team Id 2:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("teamId2", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.teamId2?.type === "pattern" &&
                        "Please correct format"}
                      {errors.teamId2?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <Alert variant="warning">
                  <Alert.Heading>Privacy Policy</Alert.Heading>
                  <p>
                    All information saved to your profile will be available to
                    public users of this website. Do not save any information
                    that you do not want visitors of the website to see.{" "}
                    <a href="/privacy" target="_blank">
                      Click here
                    </a>{" "}
                    to see our full privacy policy.
                  </p>
                </Alert>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={12}>&nbsp;</Col>
            </Row>
            <Row className="show-grid center">
              <Col xs={12}>
                <Button type="submit" size="lg">
                  Save Changes
                </Button>
              </Col>
            </Row>
          </form>
          <HighlightModal
            show={highlightShow}
            fullscreen={true}
            dialogClassName="modal-90w"
            onHide={() =>
              setHightlighData({
                highlightShow: false,
                highlightEspId: "",
                highlightName: "",
                highlightLink: "",
                highlightDisplayOrder: "",
              })
            }
          />
          <TeamModal
            show={teamShow}
            fullscreen={true}
            dialogClassName="modal-90w"
            onHide={() =>
              setTeamData({
                teamShow: false,
                teamEspId: "",
                teamDisplayName: "",
                teamJerseyNumber: "",
                teamDateStart: "",
                teamDateEnd: "",
                teamOrgName: "",
                teamPrimaryPosition: "",
                teamSecondryPosition: "",
                teamCoachName: "",
                teamEmail: "",
                teamPhone: "",
                teamLink: "",
                teamScheduleLink: "",
                teamAchievements: "",
              })
            }
          />
          <ScheduleModal
            show={scheduleShow}
            fullscreen={true}
            dialogClassName="modal-90w"
            onHide={() =>
              setScheduleData({
                scheduleShow: false,
                scheduleEspId: "",
                scheduleDisplayName: "",
                scheduleDateStart: "",
                scheduleDateEnd: "",
                scheduleCity: "",
                scheduleAddrState: "",
                scheduleLink: "",
              })
            }
          />
        </>
      )}
    </div>
  );
}
