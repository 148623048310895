import React, { useState, useEffect } from "react";
import config from "../config";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../lib/contextLib";
import { useParams } from "react-router-dom";
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";
import "./TeamPrint.css";

export default function TeamPrint() {
  const [athletes, setAthletes] = useState();
  const { clubId } = useParams();
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function loadAthletes() {
      return API.get("esp", `/team/athletes/${clubId}`);
    }

    document.title = clubId + " - EliteSoccerPlayers.com!";
    async function onLoad() {
      try {
        const athletes = await loadAthletes();
        setAthletes(athletes);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated, clubId]);

  function renderAthleteList(athletes) {
    return (
      <>
        {athletes.map(
          (
            {
              nameFirst,
              nameLast,
              seoName,
              displayOrder,
              jerseyNumber,
              graduationYear,
              city,
              addrState,
              picture,
              teamId,
              teamId2,
            },
            i
          ) => (
            <>
              <Col xs={12} sm={3} className="card-col" key={i}>
                <Card>
                  <span className="card-img-top">
                    {picture ? (
                      <Card.Img
                        alt={nameFirst + " " + nameLast}
                        variant="top"
                        className="img-fluid"
                        src={config.UPLOAD_URL + picture}
                      />
                    ) : (
                      <Card.Img
                        variant="top"
                        className="img-fluid"
                        src="https://static.wixstatic.com/media/437a91_1cf77244552f4b9da1a3fa3ff0f8a03b~mv2.png/v1/fill/w_112,h_64,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/TNT%20Solo%20Transparent%20BG.png"
                      />
                    )}
                  </span>
                  <Card.Body>
                    <Card.Title>
                      {nameFirst}&nbsp;{nameLast}&nbsp;#{jerseyNumber}
                    </Card.Title>
                    <Card.Text>
                      <Row>
                        {displayOrder !== 1 ? (
                          <>
                            <Col xs={8}>
                              Team:&nbsp;{teamId}
                              {teamId2 ? <>,&nbsp;{teamId2}</> : ""}
                              <br />
                              Grad&nbsp;Year:&nbsp;{graduationYear}
                              <br />
                              {city},&nbsp;{addrState}
                            </Col>
                            <Col xs={4}>
                              <Image
                                src={
                                  "https://chart.googleapis.com/chart?cht=qr&chs=150x150&chl=https://www.elitesoccerplayers.com/athlete/" +
                                  seoName
                                }
                                variant="top"
                                className="img-fluid img-max-h-300"
                                alt={nameFirst + " " + nameLast}
                                responsive="true"
                              />
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col xs={12}>
                              Team:&nbsp;{teamId}
                              {teamId2 ? <>,&nbsp;{teamId2}</> : ""}
                            </Col>
                          </>
                        )}
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
                {i === 15 ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </Col>
              {i === 15 ? (
                <>
                  <div class="pagebreak"> </div>
                </>
              ) : (
                ""
              )}
            </>
          )
        )}
      </>
    );
  }

  function renderLander(athletes) {
    return (
      <div className="lander">
        <Container>
          <Row>
            <Col xs={2}>
              <img
                alt="TNT Soccer"
                src="https://static.wixstatic.com/media/437a91_1cf77244552f4b9da1a3fa3ff0f8a03b~mv2.png/v1/fill/w_112,h_64,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/TNT%20Solo%20Transparent%20BG.png"
              />
            </Col>
            <Col xs={4}>
              <h1>{clubId} Soccer Players</h1>
              https://www.tnt-soccer.com/
            </Col>
            <Col xs={4}>
              Coach: Micah McMorris
              <br />
              Phone: 319-231-7024
              <br />
              Email: micahmcmorris@icloud.com
            </Col>
            <Col xs={2}>
              <Image
                src={
                  "https://chart.googleapis.com/chart?cht=qr&chs=100x100&chl=https://elitesoccerplayers.com/team/" +
                  clubId
                }
                variant="top"
                className="img-fluid img-max-h-300"
                alt="TNT Soccer Players"
                responsive="true"
              />
            </Col>
          </Row>

          <Row className="card-row">
            {(isLoading || !athletes) && (
              <>
                <br />
                <h3>&nbsp;&nbsp;Loading...</h3>
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            {!isLoading && renderAthleteList(athletes)}
          </Row>
        </Container>
      </div>
    );
  }

  return <div className="TeamPrint">{renderLander(athletes)}</div>;
}
