import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import config from "../config";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";
import { API } from "aws-amplify";
import { randomUUID } from "crypto";
import "./Dashboard.css";

export default function Home() {
  const { isAuthenticated, userId } = useAppContext();
  const history = useHistory();
  const [athlete, setAthlete] = useState();
  const [coach, setCoach] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isAthlete, setIsAthlete] = useState(false);
  const [isCoach, setIsCoach] = useState(false);
  const [isFirst, setIsFirst] = useState(false);
  const [cognitoUserId, setCognitoUsreId] = useState("");

  useEffect(() => {
    function loadRecord(userID) {
      return API.get("esp", `/edit/${userID}`);
    }

    function setFlags(record) {
      if (record && record.espType === "athlete") {
        setAthlete(record);
        setIsAthlete(true);
        setIsCoach(false);
        setIsFirst(false);
      } else if (coach && record.espType === "coach") {
        setCoach(record);
        setIsCoach(true);
        setIsAthlete(false);
        setIsFirst(false);
      } else {
        setIsAthlete(false);
        setIsCoach(false);
        setIsFirst(true);
      }

      if (isAthlete & athlete) {
        document.title =
          "EliteSoccerPlayers.com - Dashboard - " +
          athlete.nameFirst +
          " " +
          athlete.nameLast;
      } else if (isCoach && coach) {
        document.title =
          "EliteSoccerPlayers.com - Dashboard - " +
          coach.nameFirst +
          " " +
          coach.nameLast;
      }
    }

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      let record = null;

      if (typeof userId !== "undefined" && userId !== "") {
        record = await loadRecord(userId);
        setCognitoUsreId(userId);
        setFlags(record);
      } else {
        if (await Auth.currentSession()) {
          await Auth.currentAuthenticatedUser()
            .then((res) => {
              let usrId =
                res.signInUserSession.idToken.payload["cognito:username"];
              record = loadRecord(usrId).then((rcrd) => {
                setCognitoUsreId(usrId);
                setFlags(rcrd);
              });
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function setupAthlete() {
    const confirmed = window.confirm(
      "Are you sure you want to select Athlete as your account type?"
    );

    if (!confirmed) {
      return;
    }

    // First time, create athelete record
    API.post("esp", `/athlete/create/${cognitoUserId}`, {
      body: { espId: randomUUID, espKey: cognitoUserId },
    }).then((res) => {
      history.push("/create/athlete/" + cognitoUserId);
    });
  }

  function setupCoach() {
    const confirmed = window.confirm(
      "Are you sure you want to select Coach as your account type?"
    );

    if (!confirmed) {
      return;
    }

    // First time, create athelete record
    API.post("esp", `/coach/create/${cognitoUserId}`, {
      body: { espId: randomUUID, espKey: cognitoUserId },
    }).then((res) => {
      history.push("/edit/coach/" + cognitoUserId);
    });
  }

  function renderChoose() {
    return (
      <Col xs={12} className="card-col">
        <h4>
          Chose your account type! Please choose carefully as this can not be
          changed without contacting us to reset your account.
        </h4>
        <br />
        <Button
          variant="primary"
          className="card-footer-btn"
          onClick={() => setupAthlete()}
        >
          This account is for a soccer player (Athlete)!
        </Button>
        <br />
        <br />
        <br />
        <Button
          variant="primary"
          className="card-footer-btn"
          onClick={() => setupCoach()}
        >
          This account is for a college coach (Coach)!
        </Button>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Col>
    );
  }

  function renderAthlete(athlete) {
    return (
      <Col xs={12} xl={4} className="card-col" key={athlete.espKey}>
        <Card>
          {athlete && athlete.picture ? (
            <Card.Img
              variant="top"
              className="img-fluid"
              src={config.UPLOAD_URL + athlete.picture}
            />
          ) : athlete && athlete.gender && athlete.gender === "Female" ? (
            <Card.Img
              variant="top"
              className="img-fluid"
              src="/images/soccer-player-female-silhouette.png"
            />
          ) : athlete && athlete.gender && athlete.gender === "Male" ? (
            <Card.Img
              variant="top"
              className="img-fluid"
              src="/images/soccer-player-male-silhouette.png"
            />
          ) : (
            <Card.Img
              variant="top"
              className="img-fluid"
              src="/images/soccer-player-silhouette.png"
            />
          )}
          <Card.Body>
            <Card.Title>
              {athlete && athlete.nameFirst ? (
                <>
                  {athlete.nameFirst}&nbsp;{athlete.nameLast}
                </>
              ) : (
                <>First Last Name</>
              )}
            </Card.Title>
            <Card.Text>
              {athlete && athlete.primaryPosition ? (
                <>{athlete.primaryPosition}</>
              ) : (
                <>Position</>
              )}
              <br />
              {athlete && athlete.graduationYear ? (
                <>{athlete.graduationYear}</>
              ) : (
                <>YYYY</>
              )}
              <br />
              {athlete && athlete.addrState ? (
                <>
                  {athlete.city},&nbsp;{athlete.addrState}
                </>
              ) : (
                <>City, State</>
              )}
            </Card.Text>
            <Button
              variant="primary"
              className="card-footer-btn"
              href={"/edit/athlete"}
            >
              Edit My Profile
            </Button>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  function renderCoach(coach) {
    return (
      <Col xs={12} xl={4} className="card-col" key={coach.espKey}>
        <Card>
          {coach.picture ? (
            <Card.Img variant="top" src={config.UPLOAD_URL + coach.picture} />
          ) : (
            <Card.Img variant="top" src="/images/esp_logo_green.jpeg" />
          )}
          <Card.Body>
            <Card.Title>
              {coach.nameFirst}&nbsp;{coach.nameLast}
            </Card.Title>
            <Card.Text>
              {coach.collegeName}
              <br />
              {coach.city},&nbsp;{coach.addrState}
            </Card.Text>
            <Button
              variant="primary"
              className="card-footer-btn"
              href={"/edit/coach"}
            >
              Edit My Profile
            </Button>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  function renderLander(athletes) {
    return (
      <>
        <h1>My Dashboard</h1>
        <Container>
          <Row className="show-grid card-row">
            <Col xs={12}>
              <h3 className="lander">
                Welcome to your Elite Soccer Players Dashboard!
              </h3>
            </Col>
            {isLoading && (
              <>
                <br />
                <h3>&nbsp;&nbsp;Loading...</h3>
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            {!isLoading && isFirst && renderChoose()}
            {!isLoading && isAthlete && renderAthlete(athlete)}
            {!isLoading && isCoach && renderCoach(coach)}
            {!isLoading && isAthlete && (
              <Col xs={12} xl={8}>
                <p>
                  <b>Action items to start your soocer recruiting journey!</b>
                </p>
                <p>
                  <b>Steps:</b>
                </p>
                <ol type="1">
                  <li>Update your profile on EliteSoccerPlayers.com</li>
                  <li>
                    Add your direct profile link to all of your social media
                    profiles (
                    <a
                      href={
                        "https://elitesoccerplayers.com/a/" + athlete.seoName
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://elitesoccerplayers.com/a/
                      {athlete.seoName}
                    </a>
                    )
                  </li>
                  <li>
                    Add your direct profile link to your email signatures and
                    include it when sending emails to coaches
                  </li>
                  <li>
                    When posting on social media include your profile link and
                    tag EliteSoccerPlayers.com to maximize views
                  </li>
                  <li>
                    Follow us on social media platforms (Twitter:
                    @EliteSoccerPlay, Instagram: @elitesoccerplayers).
                  </li>
                  <li>
                    Keep your profile updated with your latest upcoming scedule!
                  </li>
                  <li>
                    A custom shorter link tree page is also available if you
                    prefer to link it to your social media profiles. (
                    <a
                      href={
                        "https://elitesoccerplayers.com/lt/" + athlete.seoName
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://elitesoccerplayers.com/lt/
                      {athlete.seoName}
                    </a>
                    )
                  </li>
                </ol>
                <p>
                  <i>
                    Please keep checking back as we continue to add features to
                    help you in your recruiting process and help spread the word
                    to friends about EliteSoccerPlayers.com!
                  </i>
                </p>
              </Col>
            )}
            {!isLoading && isCoach && (
              <Col xs={12} xl={8}>
                <p>
                  <b>Action items:</b>
                </p>
                <ol type="1">
                  <li>Update your coaches profile on EliteSoccerPlayers.com</li>
                  <li>
                    Add your direct profile link to all of your social media
                    profiles (https://elitesoccerplayers.com/coach/
                    {!isLoading && coach.seoName})
                  </li>
                  <li>
                    Add your direct profile link to your email signatures and
                    include it when sending emails to coaches
                  </li>
                  <li>
                    When posting on social media include your profile link and
                    tag EliteSoccerPlayers.com to maximize views
                  </li>
                  <li>
                    Follow us on social media platforms (Twitter:
                    @EliteSoccerPlay, Instagram: @elitesoccerplayers).
                  </li>
                  <li>
                    Keep your profile updated with your latest upcoming scedule!
                  </li>
                </ol>
                <p>
                  <i>
                    Please keep checking back as we continue to add features to
                    help you in your recruiting process!
                  </i>
                </p>
              </Col>
            )}
          </Row>
        </Container>
      </>
    );
  }

  return <div className="Dashboard">{renderLander(athlete)}</div>;
}
