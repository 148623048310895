import React, { useState, useEffect } from "react";
import config from "../config";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";
import { useForm } from "react-hook-form";
import "./Home.css";

export default function Home() {
  const [athletes, setAthletes] = useState();
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    document.title =
      "EliteSoccerPlayers.com - Connecting socer players and college coaches!";
    async function onLoad() {
      setValue("gradYear", "ALL");
      setValue("gender", "ALL");

      try {
        const athletes = await loadAthletes();
        setAthletes(athletes);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [setValue]);

  function loadAthletes() {
    return API.get("esp", "/athletes/athlete");
  }

  function filterAthletes() {
    const gradYear = getValues("gradYear");
    const gender = getValues("gender");
    API.get("esp", "/athletes/filter/" + gradYear + "/" + gender).then(
      (athlts) => {
        setAthletes(athlts);
      }
    );
  }

  function handleFilterGradYearChange(event) {
    setValue("gradYear", event.target.value);
    filterAthletes();
  }

  function handleFilterGenderChange(event) {
    setValue("gender", event.target.value);
    filterAthletes();
  }

  function renderAthleteList(athletes) {
    return (
      <>
        {athletes.map(
          ({
            espKey,
            nameFirst,
            nameLast,
            seoName,
            primaryPosition,
            gender,
            graduationYear,
            addrState,
            picture,
          }) => (
            <>
              {picture && (
                <Col xs={12} xl={3} className="card-col" key={espKey}>
                  <Card>
                    <span className="card-img-top">
                      {picture ? (
                        <Card.Img
                          variant="top"
                          className="img-fluid"
                          src={config.UPLOAD_URL + picture}
                        />
                      ) : gender && gender === "Female" ? (
                        <Card.Img
                          variant="top"
                          className="img-fluid"
                          src="/images/soccer-player-female-silhouette.png"
                        />
                      ) : gender && gender === "Male" ? (
                        <Card.Img
                          variant="top"
                          className="img-fluid"
                          src="/images/soccer-player-male-silhouette.png"
                        />
                      ) : (
                        <Card.Img
                          variant="top"
                          className="img-fluid"
                          src="/images/soccer-player-silhouette.png"
                        />
                      )}
                    </span>
                    <Card.Body>
                      <Card.Title>
                        {nameFirst}&nbsp;{nameLast}
                      </Card.Title>
                      <Card.Text>
                        {primaryPosition}
                        <br />
                        {graduationYear}
                        <br />
                        {addrState}
                      </Card.Text>
                      <Button
                        variant="primary"
                        className="card-footer-btn"
                        href={"/athlete/" + seoName}
                      >
                        View Profile
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </>
          )
        )}
      </>
    );
  }

  function renderLander(athletes) {
    return (
      <div className="lander">
        <Container>
          <h3>Welcome to EliteSoccerPlayers.com!</h3>
          {!isAuthenticated && (
            <>
              <br />
              <h5>
                Would you like to play soccer in college? Are you a middle or
                high school aged player? <a href="/signup">Sign up</a> and
                create your FREE recruiting profile now!
              </h5>
            </>
          )}
          <h1>Featured Soccer Players</h1>
          {!isLoading && (
            <>
              <form>
                <Row className="section">
                  <Col xs={6} xl={3}>
                    <label htmlFor="gradYear">
                      <strong>Graduation Year:</strong>
                    </label>
                  </Col>
                  <Col xs={6} xl={3}>
                    <select id="gradYear" onChange={handleFilterGradYearChange}>
                      <option value="ALL">ALL</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                      <option value="2030">2031</option>
                      <option value="2030">2032</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                    </select>
                    <p className="validation">
                      {errors.graduationYear?.type === "required" &&
                        "Graduatin year is required"}
                    </p>
                  </Col>
                  <Col xs={6} xl={3}>
                    <label>
                      <strong>Gender:</strong>
                    </label>
                  </Col>
                  <Col xs={6} xl={3}>
                    <select
                      {...register("gender", {})}
                      onChange={handleFilterGenderChange}
                    >
                      <option value="ALL">ALL</option>
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                    </select>
                  </Col>
                </Row>
              </form>
              {athletes && athletes.length > 0 ? (
                <Row className="card-row">{renderAthleteList(athletes)}</Row>
              ) : (
                <p>
                  <strong>
                    No rows returned for the filters selected above!
                  </strong>
                </p>
              )}
            </>
          )}
          <br />
          <p>
            <a href="/contactus">Contact Us</a> to find out how you can become
            featured on EliteSoccerPlayers.com!
          </p>
          {!isAuthenticated && (
            <>
              <br />
              <br />
              <h5>
                EliteSoccerPlayers.com was created by soccer parents and college
                coaches who are familiar with the college soccer recruiting
                process and wanted to create a FREE platform for all players to
                use to help them get noticed and live out their dreams of
                playing the great sport of soccer in college.
              </h5>
            </>
          )}
        </Container>
      </div>
    );
  }

  return <div className="Home">{renderLander(athletes)}</div>;
}
