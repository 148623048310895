import { useState, useEffect, useRef } from "react";
import { Auth } from "aws-amplify";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import "./ForgotPassword.css";

export default function ForgotPassword() {
  const history = useHistory();
  const [form, setForm] = useState();
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const onhCaptchaLoad = () => {
    captchaRef.current.execute();
  };

  useEffect(() => {
    async function onLoad() {
      const fields = ["email"];
      fields.forEach((field) => setValue(field, ""));
      setForm(form);
    }

    onLoad();
  }, [form, setValue, token]);

  function resetPassword(username) {
    Auth.forgotPassword(username)
      .then((data) => console.log(data))
      .catch((err) => console.log(err));

    var verificationCode = prompt(
      "Please input password reset verification code that was emailed to you from no-reply@verificationemail.com.  Please check your junk email folder if you do not see the email in your inbox.  It may take 10 to 15 minutes for this email to arrive.",
      ""
    );
    var newPassword = prompt("Enter new password ", "");
    confirmPassword(username, verificationCode, newPassword);
  }

  // confirmPassword can be separately built out as follows...
  function confirmPassword(username, verificationCode, newPassword) {
    Auth.forgotPasswordSubmit(username, verificationCode, newPassword)
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  }

  async function onSubmit(data) {
    try {
      await resetPassword(data.email);

      history.push("/login");
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }

    history.push("/");
  }

  return (
    <div className="ForgotPassword">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1>Forgot Password</h1>
        <p>Please enter the email address you registered with!</p>
        <Col xs={12}>
          <label htmlFor="email">
            <b>Email Address:</b>
          </label>
        </Col>
        <Col xs={12}>
          <input
            {...register("email", {
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email",
              },
            })}
          />
          <p className="validation">
            {errors.email?.type === "required" && "Field is required"}
            {errors.email?.type === "pattern" && "Please correct format"}
          </p>
        </Col>
        <Col xs={12}>
          <HCaptcha
            sitekey="bd0b4e41-8d27-445c-8709-6b0c75bcc8dc"
            onLoad={onhCaptchaLoad}
            onVerify={setToken}
            ref={captchaRef}
          />
        </Col>
        <Col xs={12}>&nbsp;</Col>
        <Col xs={12}>
          <Button type="submit" size="lg">
            Submit
          </Button>
        </Col>
        <Col xs={12}>&nbsp;</Col>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </form>
    </div>
  );
}
