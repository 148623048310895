const config = {
  MAX_ATTACHMENT_SIZE: 5000000,
  STRIPE_KEY:
    "pk_test_51KRiFWIFyNXNPlXJx9Rm0jvFRxSjNblxWKxlnrfxWz9DQ5nhKgNUDNlhFSYS28DPYxMjif51bVL3YSj6nkHnvDuz00njuqiaTZ",
  SENTRY_DSN: "https://5f83aa2e21064e47bab8a1f308f940eb@sentry.io/5185720",
  DEV_UPLOAD_URL:
    "https://dev-esp-storage-uploadsbucketc4b27cc7-1dtqs6evx3h62.s3.us-east-1.amazonaws.com/public/",
  UPLOAD_URL: "https://d1pmnubm3w7t9h.cloudfront.net/public/",
  CONTACT_US_SUBJECT: "EliteSoccerPlayers.com - Contact Us Form",
  PROFILE_SAVED_SUBJECT: "EliteSoccerPlayers.com - Profile Saved",
  PROFILE_CREATED_SUBJECT: "EliteSoccerPlayers.com - New Profile Created",
  PROFILE_UPDATED_SUBJECT: "EliteSoccerPlayers.com - Profile Updated",
  CONTACT_US_TOPIC_ARN: "arn:aws:sns:us-east-1:984351765331:esp-contact-us",
  RECAPTCHA_SITE_KEY: "6Lf_NYofAAAAAO3h7q4Ulj16QaWu7YvSzbzNHmHA",
  RECAPTCHA_SECRET_KEY: "6Lf_NYofAAAAAAWz6Jc5onj5JZChfmk_ajRlj3Vy",
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_BUCKET,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_URL,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
};

export default config;
