import { useRef, useState, useEffect } from "react";
import AWS from "aws-sdk";
import { API } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import { s3Upload, s3Delete } from "../lib/awsLib";
import config from "../config";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { onError } from "../lib/errorLib";
import "./AthleteEdit.css";

export default function AthleteCreate() {
  const { id } = useParams();
  const { isAuthenticated, userId } = useAppContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const isAddMode = false;
  const file = useRef(null);
  const [athlete, setAthlete] = useState();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    function loadAthlete() {
      if (userId) {
        return API.get("esp", `/athlete/edit/${userId}`);
      } else {
        return API.get("esp", `/athlete/edit/${id}`);
      }
    }

    async function onLoad() {
      try {
        setIsLoading(true);
        if (!isAddMode) {
          await loadAthlete().then((athlete) => {
            const fields = [
              "espId",
              "espKey",
              "academicAchievements",
              "city",
              "displayOrder",
              "email",
              "graduationYear",
              "nameFirst",
              "nameLast",
              "picture",
              "primaryPosition",
              "recruitmentStatus",
              "seoName",
              "addrState",
            ];
            fields.forEach((field) => setValue(field, athlete[field]));
            setAthlete(athlete);
            if (athlete) {
              document.title = "EliteSoccerPlayers.com - Create Profile";
            }
          });
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);

        onError(e);
      }
    }

    onLoad();
  }, [isAuthenticated, isAddMode, setValue, userId, id]);

  function formatFilename(str) {
    return str.substring(str.lastIndexOf("--") + 2);
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  function saveAthlete(athlete) {
    return API.put("esp", `/athlete/update/min/${userId}`, {
      body: athlete,
    });
  }

  async function onSubmit(data) {
    setIsLoading(true);

    console.log("Save:", data);

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    if (file.current) {
      let prevFileName = data.picture;
      data.picture = await s3Upload(data.espId, file.current);
      await s3Delete(prevFileName);
    }

    saveAthlete(data).then((res) => {
      // Initialize the Amazon Cognito credentials provider
      AWS.config.region = "us-east-1"; // Region
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
      });

      var sns = new AWS.SNS();

      var params = {
        Message:
          "Name: " +
          data.nameFirst +
          " " +
          data.nameLast +
          "\n" +
          " Email: " +
          data.email +
          "\n" +
          " SEO Name: " +
          data.seoName +
          "\n" +
          " Id: " +
          data.espId +
          "\n\n",
        Subject: config.PROFILE_CREATED_SUBJECT,
        TopicArn: config.CONTACT_US_TOPIC_ARN,
      };

      sns.publish(params, function (err, data) {
        if (err) {
          //console.log(err, err.stack); // an error occurred
        } else {
          //console.log(data); // successful response
        }
      });
      history.push("/edit/athlete/" + id);
    });
  }

  return (
    <div className="AthleteEdit">
      {(isLoading || !athlete) && (
        <>
          <br />
          <h3>&nbsp;&nbsp;Loading...</h3>
          <br />
          <br />
          <br />
          <br />
        </>
      )}
      {!isLoading && athlete && (
        <>
          <div className="lander">
            <h1>Create My Recruiting Profile</h1>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" {...register("espKeyOrig")} />
            <input type="hidden" {...register("displayOrder")} />
            <Row className="section">
              <Col xs={12} xl={4}>
                {athlete.picture ? (
                  <Image
                    src={config.UPLOAD_URL + athlete.picture}
                    alt="ESP"
                    className="img-sz"
                  />
                ) : (
                  <Image
                    src="/images/esp_logo_green.jpeg"
                    width="100%"
                    alt="ESP"
                  />
                )}
                {athlete.picture && <p>{formatFilename(athlete.picture)}</p>}
                <br />
                <span>*Select Profile Picture (jpeg, jpg, gif, png, svg):</span>
                <input onChange={handleFileChange} type="file" />
              </Col>
              <Col xs={12} xl={8}>
                <Row className="show-grid">
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label htmlFor="nameFirst">*First Name:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("nameFirst", {
                        pattern: /^[A-Za-z ]+$/i,
                        required: true,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.nameFirst?.type === "required" &&
                        "Field is required"}
                      {errors.nameFirst?.type === "pattern" &&
                        "Field must be letters only"}
                      {errors.nameFirst?.type === "maxLength" &&
                        "Field must be 35 numbers or less"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label htmlFor="nameLast">*Last Name:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("nameLast", {
                        pattern: /^[A-Za-z ]+$/i,
                        required: true,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.nameLast?.type === "required" &&
                        "Field is required"}
                      {errors.nameLast?.type === "pattern" &&
                        "Please correct format"}
                      {errors.nameLast?.type === "maxLength" &&
                        "Field must be 35 numbers or less"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label htmlFor="graduationYear">*Graduation Year:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select {...register("graduationYear", { required: true })}>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                      <option value="2030">2031</option>
                      <option value="2030">2032</option>
                    </select>
                    <p className="validation">
                      {errors.graduationYear?.type === "required" &&
                        "Graduatin year is required"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label>*Primary Position:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select
                      {...register("primaryPosition", { required: true })}
                    >
                      <option value="Goalkeeper (1)">Goalkeeper (1)</option>
                      <option value="Outside Back (2,3)">
                        Outside Back (2,3)
                      </option>
                      <option value="Center Back (4, 5)">
                        Center Back (4, 5)
                      </option>
                      <option value="Defensive Midfielder (6)">
                        Defensive Midfielder (6)
                      </option>
                      <option value="Winger (7, 11)">Winger (7, 11)</option>
                      <option value="Central Midfielder (8)">
                        Central Midfielder (8)
                      </option>
                      <option value="Striker (9)">Striker (9)</option>
                      <option value="Attacking Midfielder (10)">
                        Attacking Midfielder (10)
                      </option>
                    </select>
                    <p className="validation">
                      {errors.primaryPosition?.type === "required" &&
                        "Field is required"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label>*Recruitment Status:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select
                      {...register("recruitmentStatus", { required: true })}
                    >
                      <option value="Open">Open</option>
                      <option value="Committed">Committed</option>
                      <option value="No Looking">Not Looking</option>
                    </select>
                    <p className="validation">
                      {errors.recruitmentStatus?.type === "required" &&
                        "Field is required"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Contact Information</b>
                </h4>
              </Col>
              <Col xs={12}>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>*Email:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Please enter a valid email",
                        },
                      })}
                    />
                    <p className="validation">
                      {errors.email?.type === "required" && "Field is required"}
                      {errors.email?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>*City:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("city", {
                        required: true,
                        pattern: /^[A-Za-z ]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.city?.type === "required" && "Field is required"}
                      {errors.city?.type === "pattern" &&
                        "Please correct format"}
                      {errors.city?.type === "maxLength" &&
                        "Field must be 35 characters or less"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>*State:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select {...register("addrState", { required: true })}>
                      <option value="---">---</option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District of Columbia">
                        District of Columbia
                      </option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Guam">Guam</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Northern Marianas Islands">
                        Northern Marianas Islands
                      </option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Virgin Islands">Virgin Islands</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                    </select>
                    <p className="validation">
                      {errors.addrState?.type === "required" &&
                        "Field is required"}
                      {errors.addrState?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={12}>&nbsp;</Col>
            </Row>
            <Row className="show-grid center">
              <Col xs={12}>
                <Button type="submit" size="lg">
                  Save Changes
                </Button>
              </Col>
            </Row>
          </form>
        </>
      )}
    </div>
  );
}
