import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppContext } from "../lib/contextLib";
import "./HighlightVideoGuide.css";

export default function HighlightVideoGuide() {
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = "EliteSoccerPlayers.com - Highlight Video Guide";
    async function onLoad() {
      // if (!isAuthenticated) {
      //   return;
      // }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  return (
    <div className="HighlightVideoGuide container">
      <center>
        <h1>Guide to Making Soccer Highligh Videos</h1>
      </center>
      <br />
      <Row className="show-grid section">
        <Col xs={12} xl={12}>
          <p>Create and share higlight videos regularly.</p>
          <p>Each video should be 3-5 minutes long.</p>
          <p>
            Add title slide to video showing your player profile details,
            opponent team names and dates the games occured
          </p>
          <p>
            Highlight videos should show athletic ability, technical skills and
            tatical play.
          </p>
        </Col>
      </Row>
      <Row className="show-grid section">
        <Col xs={12}>
          <p>
            Include as many of the following items as possible in your video:
          </p>
        </Col>
        <Col xs={12}>
          <ul>
            <li>Athleticism</li>
            <li>Physicality</li>
            <li>Winning the ball</li>
            <li>
              Passing ability on the ground and in the air. Short distance and
              long distance
            </li>
            <li>switching the field</li>
            <li>First touch. Settling the ball</li>
            <li>Heading the ball</li>
            <li>1v1 attacking</li>
            <li>1v1 defending</li>
            <li>Off the ball supporting the run</li>
            <li>communication, hand motions, organaizing the team</li>
            <li>shooting</li>
            <li>dribbling at pace in open field</li>
            <li>composure on the ball to allow teammates to join the play</li>
            <li>assits</li>
            <li>winning tackles</li>
            <li>shielding</li>
            <li>intercepting passes</li>
            <li>your pace</li>
            <li>supporting, getting back and defending and winning the ball</li>
            <li>individual ball skills</li>
            <li>settling the ball out of the air</li>
            <li>1v1 shooting</li>
            <li>give and go's</li>
            <li>turing into space</li>
            <li>turing on a defender</li>
            <li>looking over your shoulder</li>
            <li>playing out of the back</li>
            <li>aerial challenges</li>
            <li>driven balls</li>
            <li>clearances</li>
            <li>suportive runs. show full results of the play good or bad</li>
          </ul>
        </Col>
        <Col xs={12} xl={4}>
          <p>Goal Keepers:</p>
          <ul>
            <li>saves</li>
            <li>handling crosses</li>
            <li>corners</li>
            <li>distrubution with feet and hands</li>
            <li>1v1 saves</li>
            <li>coming out of the box to play/clear</li>
            <li>communication</li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}
