import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Athlete from "./containers/Athlete";
import AthleteEdit from "./containers/AthleteEdit";
import AthleteEditAdmin from "./containers/AthleteEditAdmin";
import Login from "./containers/Login";
import Notes from "./containers/Notes";
import Signup from "./containers/Signup";
import NewNote from "./containers/NewNote";
import Dashboard from "./containers/Dashboard";
import Settings from "./containers/Settings";
import ContactUs from "./containers/ContactUs";
import NotFound from "./containers/NotFound";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import CoachEdit from "./containers/CoachEdit";
import Team from "./containers/Team";
import TeamEdit from "./containers/TeamEdit";
import AthleteLinkTree from "./containers/AthleteLinkTree";
import TeamPrint from "./containers/TeamPrint";
import Admin from "./containers/Admin";
import AthleteCreate from "./containers/AthleteCreate";
import HighlightVideoGuide from "./containers/HighlightVideoGuide";
import GuideCollege from "./containers/GuideCollege";
import ForgotPassword from "./containers/ForgotPassword";
import Athletes from "./containers/Athletes";
import Privacy from "./containers/Privacy";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <UnauthenticatedRoute exact path="/athletes">
        <Athletes />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/athlete/:id">
        <Athlete />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/a/:id">
        <Athlete />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/lt/:id">
        <AthleteLinkTree />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/alt/:id">
        <AthleteLinkTree />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/college-guide">
        <GuideCollege />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/highlight-video-guide">
        <HighlightVideoGuide />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/edit/athlete">
        <AthleteEdit />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/edit/athlete/admin/:id">
        <AthleteEditAdmin />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/create/athlete/:id">
        <AthleteCreate />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/edit/coach">
        <CoachEdit />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/edit/coach/:id">
        <CoachEdit />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/edit/team">
        <TeamEdit />
      </AuthenticatedRoute>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/forgot-password">
        <ForgotPassword />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/team/:clubId">
        <Team />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/team/print/:clubId">
        <TeamPrint />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/contactus">
        <ContactUs />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/dashboard">
        <Dashboard />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings">
        <Settings />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/notes/new">
        <NewNote />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/notes/:id">
        <Notes />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/admin">
        <Admin />
      </AuthenticatedRoute>
      <UnauthenticatedRoute exact path="/privacy">
        <Privacy />
      </UnauthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
