import { Storage } from "aws-amplify";

export async function s3Upload(id, file) {
  const filename = `${id}-${Date.now()}--${file.name}`;

  const stored = await Storage.put(filename, file, {
    contentType: file.type,
  });

  console.log("storedKey: ", stored.key);

  return stored.key;
}

export async function s3Delete(filename) {
  console.log("Delete File: ", filename);
  await Storage.remove(filename)
    .then((result) => console.log(result))
    .catch((err) => console.log(err));
}
