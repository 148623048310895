import React, { useRef, useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import { s3Upload, s3Delete } from "../lib/awsLib";
import config from "../config";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { onError } from "../lib/errorLib";
import "./CoachEdit.css";

export default function CoachEdit() {
  const { id } = useParams();
  const { isAuthenticated, userId } = useAppContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const isAddMode = false;
  const file = useRef(null);
  const [coach, setCoach] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    function loadCoach() {
      if (userId) {
        return API.get("esp", `/coach/edit/${userId}`);
      } else {
        return API.get("esp", `/coach/edit/${id}`);
      }
    }

    async function onLoad() {
      try {
        setIsLoading(true);
        if (!isAddMode) {
          await loadCoach().then((coach) => {
            const fields = [
              "espId",
              "espKey",
              "address",
              "bio",
              "city",
              "collegeName",
              "email",
              "facebook",
              "instagram",
              "nameFirst",
              "nameLast",
              "phone",
              "picture",
              "recruitmentStatus",
              "seoName",
              "snapchat",
              "addrState",
              "tiktok",
              "twitter",
              "zip",
            ];
            fields.forEach((field) => setValue(field, coach[field]));
            setCoach(coach);

            if (coach) {
              document.title =
                "EliteSoccerPlayers.com - Profile - " +
                coach.nameFirst +
                " " +
                coach.nameLast +
                " " +
                coach.collegeName;
            }
          });
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);

        onError(e);
      }
    }

    onLoad();
  }, [isAuthenticated, isAddMode, setValue, userId, id]);

  function formatFilename(str) {
    return str.substring(str.lastIndexOf("--") + 2);
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  function saveCoach(coach) {
    return API.put("esp", `/coach/update/${userId}`, {
      body: coach,
    });
  }

  async function onSubmit(data) {
    setIsLoading(true);

    console.log("Save:", data);

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    if (file.current) {
      let prevFileName = data.picture;
      data.picture = await s3Upload(data.espId, file.current);
      await s3Delete(prevFileName);
    }

    data.displayOrder = 200;

    saveCoach(data).then((res) => {
      history.push("/dashboard");
    });
  }

  return (
    <div className="CoachEdit">
      {(isLoading || !coach) && (
        <>
          <br />
          <h3>&nbsp;&nbsp;Loading...</h3>
          <br />
          <br />
          <br />
          <br />
        </>
      )}
      {!isLoading && coach && (
        <>
          <div className="lander">
            <h1>Edit My Coach Profile</h1>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" {...register("espKeyOrig")} />
            <Row className="section">
              <Col xs={12} xl={4}>
                {coach.picture ? (
                  <Image
                    src={config.UPLOAD_URL + coach.picture}
                    width="480px"
                    alt="ESP"
                  />
                ) : (
                  <Image
                    src="/images/esp_logo_green.jpeg"
                    width="480px"
                    alt="ESP"
                  />
                )}
                {coach.picture && <p>{formatFilename(coach.picture)}</p>}
                <input onChange={handleFileChange} type="file" />
              </Col>
              <Col xs={12} xl={8}>
                <Row className="show-grid">
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label>College Name:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("collegeName", {
                        required: true,
                        maxLength: 100,
                      })}
                    />
                    <p className="validation">
                      {errors.collegeName?.type === "required" &&
                        "Field is required"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label htmlFor="nameFirst">First Name:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("nameFirst", {
                        pattern: /^[A-Za-z ]+$/i,
                        required: true,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.nameFirst?.type === "required" &&
                        "Field is required"}
                      {errors.nameFirst?.type === "pattern" &&
                        "Field must be letters only"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label htmlFor="nameLast">Last Name:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("nameLast", {
                        pattern: /^[A-Za-z ]+$/i,
                        required: true,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.nameLast?.type === "required" &&
                        "Field is required"}
                      {errors.nameLast?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12}>&nbsp;</Col>
                  <Col xs={12} xl={4}>
                    <label>Recruitment Status:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select
                      {...register("recruitmentStatus", { required: true })}
                    >
                      <option value="Activley Recruiting">
                        Activley Recruiting
                      </option>
                      <option value="Not Recruiting">Not Recruiting</option>
                    </select>
                    <p className="validation">
                      {errors.recruitmentStatus?.type === "required" &&
                        "Field is required"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Bio</b>
                </h4>
              </Col>
              <Col xs={12}>
                <textarea
                  rows="2"
                  cols="52"
                  className="textarea"
                  {...register("bio", {
                    maxLength: 500,
                  })}
                />
                <p className="validation">
                  {errors.bio?.type === "required" && "Field is required"}
                </p>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Contact Information</b>
                </h4>
              </Col>
              <Col xs={12}>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Email:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Please enter a valid email",
                        },
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.email?.type === "required" && "Field is required"}
                      {errors.email?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Phone:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("phone", {
                        minLength: 10,
                        maxLength: 17,
                        required: true,
                      })}
                    />
                    <p className="validation">
                      {errors.phone?.type === "required" && "Field is required"}
                      {errors.phone?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Address:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("address", {
                        pattern: /^[A-Za-z0-9]+\.?/i,
                        required: true,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.address?.type === "required" &&
                        "Field is required"}
                      {errors.address?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>City:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("city", {
                        pattern: /^[A-Za-z]+$/i,
                        required: true,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.city?.type === "required" && "Field is required"}
                      {errors.city?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>State:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <select {...register("addrState", { required: true })}>
                      <option value="---">---</option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District of Columbia">
                        District of Columbia
                      </option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Guam">Guam</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Northern Marianas Islands">
                        Northern Marianas Islands
                      </option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Virgin Islands">Virgin Islands</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                    </select>
                    <p className="validation">
                      {errors.addrState?.type === "required" &&
                        "Field is required"}
                    </p>
                  </Col>
                  <Col xs={12} xl={4}>
                    <label>Zip Code:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("zip", {
                        pattern: /^[0-9]+$/i,
                        required: true,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.zip?.type === "required" && "Field is required"}
                      {errors.zip?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>
                  <b>Social Media Account</b>
                </h4>
              </Col>
              <Col xs={12}>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Twiter:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("twitter", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.twitter?.type === "required" &&
                        "Field is required"}
                      {errors.twitter?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Instagram:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("instagram", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.instagram?.type === "required" &&
                        "Field is required"}
                      {errors.instagram?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Facebook:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("facebook", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.facebook?.type === "required" &&
                        "Field is required"}
                      {errors.facebook?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Snapchat:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("snapchat", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.snapchat?.type === "required" &&
                        "Field is required"}
                      {errors.snapchat?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                </Row>
                <Row className="show-grid">
                  <Col xs={12} xl={4}>
                    <label>Tiktok:</label>
                  </Col>
                  <Col xs={12} xl={8}>
                    <input
                      {...register("tiktok", {
                        pattern: /^[a-zA-Z0-9@_-]+$/i,
                        maxLength: 35,
                      })}
                    />
                    <p className="validation">
                      {errors.tiktok?.type === "required" &&
                        "Field is required"}
                      {errors.tiktok?.type === "pattern" &&
                        "Please correct format"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={12}>&nbsp;</Col>
            </Row>
            <Row className="show-grid center">
              <Col xs={12}>
                <Button type="submit" size="lg">
                  Save Changes
                </Button>
              </Col>
            </Row>
          </form>
        </>
      )}
    </div>
  );
}
