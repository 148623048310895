import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import config from "../config";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ListGroup from "react-bootstrap/ListGroup";
import Carousel from "react-bootstrap/Carousel";
import { useParams } from "react-router-dom";
import { onError } from "../lib/errorLib";
import "./AthleteLinkTree.css";

export default function AthleteLinkTree() {
  const { id } = useParams();
  const [athlete, setAthleteLinkTree] = useState();
  const [highlights, setHighlights] = useState([]);

  useEffect(() => {
    function loadAthleteLinkTree() {
      return API.get("esp", `/athlete/${id}`);
    }

    function loadHighlights(espId) {
      return API.get("esp", `/athlete/highlights/${espId}`);
    }

    async function onLoad() {
      try {
        const athlete = await loadAthleteLinkTree();
        setAthleteLinkTree(athlete);
        if (athlete) {
          document.title =
            "EliteSoccerPlayers.com - " +
            athlete.nameFirst +
            " " +
            athlete.nameLast +
            " " +
            athlete.addrState +
            " " +
            athlete.graduationYear;
        }
        const highlights = await loadHighlights(athlete.espId);
        setHighlights(highlights);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function renderHighlightList(highlights) {
    return (
      <>
        {highlights.map(({ espId, displayName, link }) => (
          <Row className="show-grid" key={espId}>
            <Col xs={12} className="card-col pad-bottom-5px">
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className="button"
              >
                {displayName}
              </a>
            </Col>
          </Row>
        ))}
      </>
    );
  }

  function renderCarouselItems(athName, athPhotos) {
    return [{}].concat(athPhotos).map((photo, i) =>
      i !== 0 ? (
        <Carousel.Item key={i} interval={10000}>
          <Image
            variant="top"
            className="img-fluid"
            alt={athName}
            src={config.UPLOAD_URL + photo}
          />
        </Carousel.Item>
      ) : null
    );
  }

  function renderCarousel(attchmnt, athName, athPhotos) {
    return (
      <Carousel data-interval="false" responsive="true">
        {attchmnt ? (
          <Carousel.Item key="a" interval={15000}>
            <Image
              variant="top"
              className="img-fluid"
              alt={athName}
              src={config.UPLOAD_URL + attchmnt}
            />
          </Carousel.Item>
        ) : (
          ""
        )}
        {athPhotos ? renderCarouselItems(athName, athPhotos) : ""}
      </Carousel>
    );
  }

  return (
    <div className="AthleteLinkTree">
      {athlete && (
        <div className="profile">
          <Row className="show-grid section">
            <Col xs={12} xl={12}>
              <Row className="show-grid ">
                <Col xs={12}>&nbsp;</Col>
                <Col xs={12}>
                  <h1>
                    {athlete.nameFirst}&nbsp;{athlete.nameLast}
                  </h1>
                </Col>
                <Col xs={4}>
                  <h5>Grad Year:</h5>
                </Col>
                <Col xs={8}>
                  <span className="large-txt">{athlete.graduationYear}</span>
                </Col>
                <Col xs={4}>
                  <h5>Position:</h5>
                </Col>
                <Col xs={8}>
                  <span className="large-txt">{athlete.primaryPosition}</span>
                </Col>
                <Col xs={4}>
                  <h5>City, State:</h5>
                </Col>
                <Col xs={8}>
                  <span className="large-txt">
                    {athlete.city},&nbsp;{athlete.addrState}
                  </span>
                </Col>
                <Col xs={4}>
                  <h5>Height:</h5>
                </Col>
                <Col xs={8}>
                  <span className="large-txt">{athlete.height}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          {highlights && (
            <Row className="show-grid section">
              <Col xs={12}>
                <h4>Highlights &amp; Other Profiles</h4>
              </Col>
              <Col xs={12}>
                <div class="show-grid row">
                  <div class="card-col pad-bottom-5px col-12">
                    <a
                      href={
                        "https://elitesoccerplayers.com/athlete/" +
                        athlete.seoName
                      }
                      target="_self"
                      rel="noreferrer"
                      class="button"
                    >
                      My EliteSoccerPlayers.com Profile
                    </a>
                  </div>
                </div>
                {renderHighlightList(highlights)}
              </Col>
            </Row>
          )}
          <Row className="show-grid section">
            <Col xs={12} xl={12}>
              {athlete.pictureFiles && athlete.pictureFiles.length > 0 ? (
                renderCarousel(
                  athlete.picture,
                  athlete.seoName,
                  athlete.pictureFiles
                )
              ) : (
                <Image
                  className="profile-img"
                  src={config.UPLOAD_URL + athlete.picture}
                  alt={athlete.seoName}
                />
              )}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
